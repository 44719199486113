import { forwardRef } from 'react'
import { Spinner } from 'summon-ui'
import { Box } from 'summon-ui/mantine'
import { TENANT_CONFIG } from '@config'
import { ArtStyleEnum } from '@config/enum'
import { useInventoryItems } from '@hooks'
import useAvatarStore from '@modules/inventory/hooks/useAvatarStore'
import Avatar2D from './components/Avatar2D'
import Avatar3D from './components/Avatar3D'

type AvatarPreviewProps = {
  previewMode?: boolean
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  centerProps?: any
  className?: string
  isEditor?: boolean
  isDNA?: boolean
  isDNATraits?: boolean
  background?: string | undefined
}
const { artStyle } = TENANT_CONFIG

const AvatarPreview = forwardRef(
  (
    {
      previewMode,
      centerProps,
      className,
      isDNA = false,
      isDNATraits = false,
      isEditor = false,
      background
    }: AvatarPreviewProps,
    ref
  ) => {
    const { previewItems } = useAvatarStore()
    const { isInventoryItemsLoading, inventoryItems } = useInventoryItems({ isDNA: isDNA, isDNATraits: isDNATraits })
    const avatarItems = previewMode ? previewItems : inventoryItems

    const renderAvatar = () => {
      return artStyle === ArtStyleEnum.Art2D ? (
        <Avatar2D items={avatarItems} ref={ref} />
      ) : (
        <Avatar3D
          background={background}
          isEditor={isEditor}
          centerProps={centerProps}
          preview={!!previewMode}
          items={avatarItems}
          ref={ref}
        />
      )
    }

    return (
      <Box className={className + ' w-full aspect-square max-w-2xl'}>
        {isInventoryItemsLoading ? <Spinner /> : renderAvatar()}
      </Box>
    )
  }
)

AvatarPreview.displayName = 'AvatarPreview'

export default AvatarPreview
