import { Link } from 'react-router-dom'
import { Button, Title, useDisclosure } from 'summon-ui/mantine'
import ConvertCreditsModal from '../components/ConvertCreditsModal'

const StakingView = () => {
  const [opened, { close }] = useDisclosure(true)

  return (
    <>
      <Title>Staking View</Title>
      <Link to='/staking/options'>
        <Button>Options</Button>
      </Link>
      <ConvertCreditsModal open={opened} onClose={close} />
    </>
  )
}
export default StakingView
