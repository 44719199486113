// @ts-nocheck
import { GraphQLClient } from 'graphql-request';
import { RequestInit } from 'graphql-request/dist/types.dom';
import { useQuery, useMutation, UseQueryOptions, UseMutationOptions } from '@tanstack/react-query';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };

function fetcher<TData, TVariables extends { [key: string]: any }>(client: GraphQLClient, query: string, variables?: TVariables, requestHeaders?: RequestInit['headers']) {
  return async (): Promise<TData> => client.request({
    document: query,
    variables,
    requestHeaders
  });
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** Date custom scalar type */
  DateTime: { input: any; output: any; }
  JSON: { input: any; output: any; }
};

export type Achievement = {
  __typename?: 'Achievement';
  attributes: Scalars['JSON']['output'];
  id: Scalars['ID']['output'];
  imageUrl: Scalars['String']['output'];
  isPublished: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  rank: Scalars['Float']['output'];
  status: AchievementStatus;
  xp: Scalars['Float']['output'];
};

export type AchievementInput = {
  attributes: Scalars['JSON']['input'];
  /** ID is optional and shoud be passed only for an existing achievement. Keep it null for a new achievement */
  id?: InputMaybe<Scalars['ID']['input']>;
  imageUrl: Scalars['String']['input'];
  isPublished: Scalars['Boolean']['input'];
  status: AchievementStatus;
};

export enum AchievementStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type Badge = {
  __typename?: 'Badge';
  animationUrl: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  imageUrl: Scalars['String']['output'];
  key: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type Bounty = {
  __typename?: 'Bounty';
  content: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  plainContent: Scalars['String']['output'];
  reward: BountyReward;
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type BountyReward = {
  __typename?: 'BountyReward';
  amount: Scalars['Float']['output'];
  chain: Scalars['Int']['output'];
  tokenAddress: Scalars['String']['output'];
};

export type Campaign = {
  __typename?: 'Campaign';
  categoryIds?: Maybe<Array<Scalars['String']['output']>>;
  key: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type Category = {
  __typename?: 'Category';
  backgroundUrl?: Maybe<Scalars['String']['output']>;
  campaigns: Array<Scalars['String']['output']>;
  completionPercentage?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  endTime?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isFeatured?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  position?: Maybe<Scalars['Int']['output']>;
  rewards?: Maybe<Array<Reward>>;
  startTime?: Maybe<Scalars['DateTime']['output']>;
  totalCredits?: Maybe<Scalars['Int']['output']>;
  totalXP?: Maybe<Scalars['Int']['output']>;
  urlSlug?: Maybe<Scalars['String']['output']>;
};

export type CategoryCreateInput = {
  backgroundUrl?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  endTime?: InputMaybe<Scalars['DateTime']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  position?: InputMaybe<Scalars['Int']['input']>;
  startTime?: InputMaybe<Scalars['DateTime']['input']>;
  urlSlug?: InputMaybe<Scalars['String']['input']>;
};

export type CategoryUpdateInput = {
  backgroundUrl?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  endTime?: InputMaybe<Scalars['DateTime']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isFeatured?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  startTime?: InputMaybe<Scalars['DateTime']['input']>;
  urlSlug?: InputMaybe<Scalars['String']['input']>;
};

export type Chain = {
  __typename?: 'Chain';
  address: Scalars['String']['output'];
  tokenId: Scalars['Int']['output'];
  type: Scalars['Int']['output'];
};

export type ClaimableItem = {
  __typename?: 'ClaimableItem';
  createdAt: Scalars['DateTime']['output'];
  data?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['ID']['output'];
  isUsed: Scalars['Boolean']['output'];
  itemCount: Scalars['Int']['output'];
  nonce: Scalars['Int']['output'];
  randomData: Scalars['String']['output'];
  signature: Scalars['String']['output'];
  type: ClaimableItemType;
  value?: Maybe<Scalars['Int']['output']>;
};

export enum ClaimableItemType {
  Level = 'LEVEL',
  Tier = 'TIER'
}

export enum CommentTypeEnum {
  Forum = 'FORUM',
  Proposal = 'PROPOSAL'
}

export type Constraints = {
  __typename?: 'Constraints';
  maxUnitDistributionLimit?: Maybe<Scalars['Int']['output']>;
};

export type CraftingMaterial = {
  __typename?: 'CraftingMaterial';
  count: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  imageUrl: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type CraftingRecipe = {
  __typename?: 'CraftingRecipe';
  craftedAnimationUrl: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  imageUrl: Scalars['String']['output'];
  name: Scalars['String']['output'];
  pendingAnimationUrl: Scalars['String']['output'];
  requiredMaterials: Array<CraftingMaterial>;
};

export type CreateCharmverseCommentInput = {
  content: Scalars['String']['input'];
  parentCommentId?: InputMaybe<Scalars['ID']['input']>;
  postId: Scalars['ID']['input'];
  type: CommentTypeEnum;
};

export type CreateCharmverseCommentPayload = {
  __typename?: 'CreateCharmverseCommentPayload';
  comment: PostComment;
};

export type CreateCharmverseMemberPayload = {
  __typename?: 'CreateCharmverseMemberPayload';
  user: User;
};

export type CreateCharmversePostInput = {
  contentMarkdown: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type CreateCharmversePostPayload = {
  __typename?: 'CreateCharmversePostPayload';
  post: Post;
};

export type CreateLootBoxInput = {
  name: Scalars['String']['input'];
};

export type CreateLootBoxPayload = {
  __typename?: 'CreateLootBoxPayload';
  lootBox: LootBox;
};

export type CreateLootBoxXpItemInput = {
  amount: Scalars['Int']['input'];
  lootBoxId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type CreateLootBoxXpItemPayload = {
  __typename?: 'CreateLootBoxXPItemPayload';
  lootBox: LootBox;
};

export type CreditsLeaderboard = {
  __typename?: 'CreditsLeaderboard';
  avatarUrl?: Maybe<Scalars['String']['output']>;
  creditsAmount?: Maybe<Scalars['Float']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  legacyUserId?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  username?: Maybe<Scalars['String']['output']>;
  walletAddress?: Maybe<Scalars['String']['output']>;
};

export type CreditsStats = {
  __typename?: 'CreditsStats';
  amountHyperPlayCredits: Scalars['Int']['output'];
  amountQuestCredits: Scalars['Int']['output'];
  amountReferralCredits: Scalars['Int']['output'];
  amountUserCredits: Scalars['Int']['output'];
  maxHyperPlayCredits: Scalars['Int']['output'];
  maxQuestCredits: Scalars['Int']['output'];
};

export type CurrentLevelInfo = {
  __typename?: 'CurrentLevelInfo';
  completedQuests: Scalars['Int']['output'];
  completedRequiredQuests: Scalars['Int']['output'];
  minXpToNextLevel?: Maybe<Scalars['Float']['output']>;
  nextCategorySuggested?: Maybe<Category>;
  /** @deprecated This is no longer used. Use nextCategorySuggested instead */
  nextQuestSuggested?: Maybe<Quest>;
  totalLevelRequiredQuests: Scalars['Int']['output'];
  totalLevelRequiredXp?: Maybe<Scalars['Float']['output']>;
  userLevelXp: Scalars['Float']['output'];
};

export type CustomData = {
  __typename?: 'CustomData';
  linea?: Maybe<Linea>;
  solanaWallet?: Maybe<SolanaWallet>;
  telegram?: Maybe<Telegram>;
};

export type DeleteCharmverseCommentInput = {
  commentId: Scalars['ID']['input'];
  proposalId?: InputMaybe<Scalars['ID']['input']>;
};

export type DeleteCharmverseCommentPayload = {
  __typename?: 'DeleteCharmverseCommentPayload';
  comment: PostComment;
};

export type DeleteCharmversePostInput = {
  id: Scalars['ID']['input'];
};

export type DeleteCharmversePostPayload = {
  __typename?: 'DeleteCharmversePostPayload';
  post: Post;
};

export type DeleteLootBoxPayload = {
  __typename?: 'DeleteLootBoxPayload';
  lootBox: LootBox;
};

export type DisablePartnerProductReq = {
  id: Scalars['ID']['input'];
};

export type DisablePartnerProductRes = {
  __typename?: 'DisablePartnerProductRes';
  disabled: Scalars['Boolean']['output'];
};

export type DisablePartnerReq = {
  id: Scalars['ID']['input'];
};

export type DisablePartnerRes = {
  __typename?: 'DisablePartnerRes';
  disabled: Scalars['Boolean']['output'];
};

export type EditCharmverseCommentInput = {
  commentId: Scalars['ID']['input'];
  content: Scalars['String']['input'];
  proposalId?: InputMaybe<Scalars['ID']['input']>;
};

export type EditCharmverseCommentPayload = {
  __typename?: 'EditCharmverseCommentPayload';
  comment: PostComment;
};

export type GetPartnerProductReq = {
  id: Scalars['ID']['input'];
};

export type GetPartnerProductRes = {
  __typename?: 'GetPartnerProductRes';
  product: Product;
};

export type GetPartnerReq = {
  id: Scalars['ID']['input'];
};

export type GetPartnerRes = {
  __typename?: 'GetPartnerRes';
  partner: Partner;
};

export type InputCreateReward = {
  campaignId?: InputMaybe<Scalars['String']['input']>;
  categoryId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  lootDropId?: InputMaybe<Scalars['String']['input']>;
  questId?: InputMaybe<Scalars['String']['input']>;
  rewardIntegrationId: Scalars['String']['input'];
  taskId?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  units: Scalars['Int']['input'];
  videoUrl?: InputMaybe<Scalars['String']['input']>;
};

export type InputDisableReward = {
  id: Scalars['String']['input'];
};

export type InputGetReward = {
  id: Scalars['String']['input'];
};

export type InputGetRewardIntegration = {
  id: Scalars['String']['input'];
};

export type InputRedeemReward = {
  rewardsRedeemedId: Scalars['String']['input'];
};

export type InputRewardRedeemed = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  redeemed?: InputMaybe<Scalars['Boolean']['input']>;
};

export type InputSearchReward = {
  active: Scalars['Boolean']['input'];
  campaignId?: InputMaybe<Scalars['String']['input']>;
  categoryId?: InputMaybe<Scalars['String']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  lootDropId?: InputMaybe<Scalars['String']['input']>;
  questId?: InputMaybe<Scalars['String']['input']>;
  taskId?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
};

export type InputSearchRewardIntegration = {
  active: Scalars['Boolean']['input'];
  title?: InputMaybe<Scalars['String']['input']>;
};

export type InputUpdateReward = {
  campaignId?: InputMaybe<Scalars['String']['input']>;
  categoryId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  level?: InputMaybe<Scalars['Int']['input']>;
  lootDropId?: InputMaybe<Scalars['String']['input']>;
  questId?: InputMaybe<Scalars['String']['input']>;
  rewardIntegrationId: Scalars['String']['input'];
  taskId?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  units: Scalars['Int']['input'];
  videoUrl?: InputMaybe<Scalars['String']['input']>;
};

export type InputUpdateRewardIntegration = {
  constraints?: InputMaybe<InputUpdateRewardIntegrationConstraints>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export type InputUpdateRewardIntegrationConstraints = {
  maxUnitDistributionLimit?: InputMaybe<Scalars['Int']['input']>;
};

export type InputUpdateRewardRedeemed = {
  id: Scalars['ID']['input'];
};

export type InsertPartnerProductReq = {
  contactPerson?: InputMaybe<Scalars['String']['input']>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  partnerId: Scalars['ID']['input'];
  websiteUrl?: InputMaybe<Scalars['String']['input']>;
};

export type InsertPartnerProductRes = {
  __typename?: 'InsertPartnerProductRes';
  id: Scalars['String']['output'];
};

export type InsertPartnerReq = {
  contactPerson?: InputMaybe<Scalars['String']['input']>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  websiteUrl?: InputMaybe<Scalars['String']['input']>;
};

export type InsertPartnerRes = {
  __typename?: 'InsertPartnerRes';
  token: Scalars['String']['output'];
};

export type Integration = {
  __typename?: 'Integration';
  adminWalletAddressList: Array<Scalars['String']['output']>;
  apiToken: Scalars['String']['output'];
  features: Array<IntegrationFeature>;
  id: Scalars['ID']['output'];
  inputPlaceholder?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type IntegrationFeature = {
  __typename?: 'IntegrationFeature';
  enableInput?: Maybe<Scalars['Boolean']['output']>;
  expressionAchievement: Scalars['String']['output'];
  expressionRank: Scalars['String']['output'];
  expressionXP: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  inputPlaceholder?: Maybe<Scalars['String']['output']>;
  integrationKey?: Maybe<Scalars['String']['output']>;
  iterations?: Maybe<Scalars['Int']['output']>;
  key: Scalars['String']['output'];
  manualCheckUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  type: IntegrationFeatureType;
};

export type IntegrationFeatureInput = {
  enableInput?: InputMaybe<Scalars['Boolean']['input']>;
  expressionAchievement?: InputMaybe<Scalars['String']['input']>;
  expressionRank?: InputMaybe<Scalars['String']['input']>;
  expressionXP?: InputMaybe<Scalars['String']['input']>;
  inputPlaceholder?: InputMaybe<Scalars['String']['input']>;
  iterations?: InputMaybe<Scalars['Int']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  manualCheckUrl?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<IntegrationFeatureType>;
};

export enum IntegrationFeatureType {
  OneTime = 'ONE_TIME',
  Repeat = 'REPEAT'
}

export type InventoryInput = {
  avatarContractAddress: Scalars['String']['input'];
  inventory: Array<InventoryItemInput>;
  playerAddress: Scalars['String']['input'];
};

export type InventoryItem = {
  __typename?: 'InventoryItem';
  chain?: Maybe<Chain>;
  count: Scalars['Int']['output'];
  enableVariantColors: Scalars['Boolean']['output'];
  glbUrl: Scalars['String']['output'];
  hide?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  imageUrl: Scalars['String']['output'];
  isEquipped?: Maybe<Scalars['Boolean']['output']>;
  itemType?: Maybe<Scalars['String']['output']>;
  slotType?: Maybe<Scalars['Int']['output']>;
  soulbound?: Maybe<Scalars['Boolean']['output']>;
  uri?: Maybe<Scalars['String']['output']>;
  variantColor?: Maybe<Scalars['String']['output']>;
  variants: Array<InventoryItem>;
};

export type InventoryItemInput = {
  count: Scalars['Int']['input'];
  hide?: InputMaybe<Scalars['Boolean']['input']>;
  itemId: Scalars['String']['input'];
  slotType: Scalars['Int']['input'];
  smartContractAddress: Scalars['String']['input'];
  soulbound: Scalars['Boolean']['input'];
  tokenId: Scalars['String']['input'];
  uri: Scalars['String']['input'];
};

export type InventoryItems = {
  __typename?: 'InventoryItems';
  chain?: Maybe<Chain>;
  count: Scalars['Int']['output'];
  enableVariantColors: Scalars['Boolean']['output'];
  glbUrl: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  imageUrl: Scalars['String']['output'];
  minLevel?: Maybe<Scalars['Int']['output']>;
  preview: Scalars['Boolean']['output'];
  slotType?: Maybe<Scalars['Int']['output']>;
  variantColor: Scalars['String']['output'];
  variants?: Maybe<Array<InventoryItems>>;
};

export type InventorySlot = {
  __typename?: 'InventorySlot';
  id: Scalars['ID']['output'];
  isEquippable: Scalars['Boolean']['output'];
  slotId: Scalars['Int']['output'];
  slotType: Scalars['Int']['output'];
  slotUrl?: Maybe<Scalars['String']['output']>;
};

export enum ItemType {
  Boots = 'BOOTS',
  Chest = 'CHEST',
  Helm = 'HELM',
  Pants = 'PANTS',
  Shoulders = 'SHOULDERS'
}

export type LevelInfo = {
  __typename?: 'LevelInfo';
  completedQuestCount: Scalars['Int']['output'];
  /** It will return null when user will reach max. level */
  totalLevelXp?: Maybe<Scalars['Float']['output']>;
  totalQuestCount: Scalars['Int']['output'];
  userLevelXp: Scalars['Float']['output'];
};

export type Linea = {
  __typename?: 'Linea';
  guild?: Maybe<Scalars['String']['output']>;
};

export type LineaLeaderboardUser = {
  __typename?: 'LineaLeaderboardUser';
  avatarUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lootDropMultiplier?: Maybe<Scalars['Float']['output']>;
  points: Scalars['Float']['output'];
  position: Scalars['Int']['output'];
  walletAddress?: Maybe<Scalars['String']['output']>;
};

export type LootBox = {
  __typename?: 'LootBox';
  id: Scalars['ID']['output'];
  items: Array<LootBoxItem>;
  name: Scalars['String']['output'];
};

export type LootBoxItem = XpLootBoxItem;

export type LootDrop = {
  __typename?: 'LootDrop';
  categories?: Maybe<Array<Category>>;
  categoriesIds: Array<Scalars['String']['output']>;
  completionPercentage?: Maybe<Scalars['Int']['output']>;
  coverImage: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  endTime: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  multiplier?: Maybe<Scalars['Int']['output']>;
  multiplierPerCategory: Scalars['Int']['output'];
  multiplierPerLevel: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  participantCount: Scalars['Int']['output'];
  rewardPoolInfo: Scalars['String']['output'];
  rewards?: Maybe<Array<Reward>>;
  startTime: Scalars['DateTime']['output'];
  totalMultiplier: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  urlSlug: Scalars['String']['output'];
};

export type LootDropInput = {
  categoriesIds: Array<Scalars['String']['input']>;
  coverImage: Scalars['String']['input'];
  description: Scalars['String']['input'];
  endTime: Scalars['DateTime']['input'];
  multiplierPerCategory: Scalars['Int']['input'];
  multiplierPerLevel: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  rewardPoolInfo: Scalars['String']['input'];
  startTime: Scalars['DateTime']['input'];
  urlSlug: Scalars['String']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addAdminUserWalletAddress?: Maybe<Scalars['Boolean']['output']>;
  addOnChainTransactionHistory: Scalars['Boolean']['output'];
  addUserReferral: Scalars['Boolean']['output'];
  archiveQuest?: Maybe<Scalars['Boolean']['output']>;
  checkTaskCompletion?: Maybe<Scalars['Boolean']['output']>;
  checkTaskCompletion2: Scalars['String']['output'];
  completeTutorial?: Maybe<Scalars['Boolean']['output']>;
  completeUserOnboarding?: Maybe<Scalars['Boolean']['output']>;
  consentLootDropTerms: Scalars['Boolean']['output'];
  /** @deprecated Will be removed in the future */
  craftItem: Scalars['Boolean']['output'];
  /** @deprecated Will be removed in the future */
  createAchievement: Achievement;
  createCategory: Category;
  /** @deprecated Will be removed in the future */
  createCharmverseComment: CreateCharmverseCommentPayload;
  /** @deprecated Will be removed in the future */
  createCharmverseMember: CreateCharmverseMemberPayload;
  /** @deprecated Will be removed in the future */
  createCharmversePost: CreateCharmversePostPayload;
  createIntegration: Integration;
  createIntegrationFeature: IntegrationFeature;
  /** @deprecated Will be removed in the future */
  createLootBox: CreateLootBoxPayload;
  /** @deprecated Will be removed in the future */
  createLootBoxItem: CreateLootBoxXpItemPayload;
  /** @deprecated Will be removed in the future */
  createLootBoxXPItem: CreateLootBoxXpItemPayload;
  createLootDrop: LootDrop;
  createQuest: Quest;
  createReferralCode?: Maybe<Scalars['Boolean']['output']>;
  createReward: Reward;
  createTask: Task;
  createTenant: Tenant;
  /** @deprecated Will be removed in the future */
  deleteAchievement?: Maybe<Scalars['Boolean']['output']>;
  deleteCategory?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Will be removed in the future */
  deleteCharmverseComment: DeleteCharmverseCommentPayload;
  /** @deprecated Will be removed in the future */
  deleteCharmversePost: DeleteCharmversePostPayload;
  deleteIntegrationFeature?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Will be removed in the future */
  deleteLootBox: LootBox;
  /** @deprecated Will be removed in the future */
  deleteLootBoxItem: DeleteLootBoxPayload;
  deleteLootDrop: Scalars['Boolean']['output'];
  deleteQuest?: Maybe<Scalars['Boolean']['output']>;
  deleteTask?: Maybe<Scalars['Boolean']['output']>;
  disablePartner: DisablePartnerRes;
  disablePartnerProduct: DisablePartnerProductRes;
  disableReward: Reward;
  disconnectFromSocialNetwork: User;
  echo?: Maybe<Scalars['JSON']['output']>;
  /** @deprecated Will be removed in the future */
  editCharmverseComment: EditCharmverseCommentPayload;
  ensureLevel0ClaimableItemExist: ClaimableItem;
  equipInventoryItems?: Maybe<Scalars['Boolean']['output']>;
  insertPartner: InsertPartnerRes;
  insertPartnerProduct: InsertPartnerProductRes;
  markClaimableItemAsUsed?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Will be removed in the future */
  migrateIntegrations?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Will be removed in the future */
  migrateQuests?: Maybe<Scalars['Boolean']['output']>;
  mintAvatar: Scalars['Boolean']['output'];
  mintLevelUp: Scalars['Boolean']['output'];
  patchPartner: PatchPartnerRes;
  patchPartnerProduct: PatchPartnerProductRes;
  publishQuest?: Maybe<Scalars['Boolean']['output']>;
  redeemReward: Scalars['JSON']['output'];
  removeAdminUserWalletAddress?: Maybe<Scalars['Boolean']['output']>;
  saveAvatar: User;
  saveQuest?: Maybe<Quest>;
  setCategoriesOrder: Array<Category>;
  updateCategory: Category;
  updateCurrentUserSettings: User;
  updateIntegrationFeature?: Maybe<IntegrationFeature>;
  /** @deprecated Will be removed in the future */
  updateLootBoxName: UpdateLootBoxPayload;
  updateLootDrop: LootDrop;
  updatePartnerUser: Scalars['Boolean']['output'];
  updateQuest: Quest;
  updateReward: Reward;
  updateRewardIntegration: RewardIntegration;
  updateRewardRedeemed: Scalars['Boolean']['output'];
  updateTask: Task;
  /** @deprecated Will be removed in the future */
  voteOnCharmverseComment: VoteOnCharmverseCommentPayload;
};


export type MutationAddAdminUserWalletAddressArgs = {
  walletAddress: Scalars['String']['input'];
};


export type MutationAddOnChainTransactionHistoryArgs = {
  chainId: Scalars['Int']['input'];
  contractAddress: Scalars['String']['input'];
  description: Scalars['String']['input'];
  txAddress: Scalars['String']['input'];
  txLink: Scalars['String']['input'];
  value?: InputMaybe<Scalars['Int']['input']>;
};


export type MutationAddUserReferralArgs = {
  referralCode: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationArchiveQuestArgs = {
  id: Scalars['ID']['input'];
};


export type MutationCheckTaskCompletionArgs = {
  questId: Scalars['ID']['input'];
  taskId: Scalars['ID']['input'];
};


export type MutationCheckTaskCompletion2Args = {
  questId: Scalars['ID']['input'];
  taskId: Scalars['ID']['input'];
};


export type MutationConsentLootDropTermsArgs = {
  lootDropId: Scalars['ID']['input'];
};


export type MutationCraftItemArgs = {
  recipeId: Scalars['ID']['input'];
};


export type MutationCreateAchievementArgs = {
  asset: Scalars['JSON']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  imageUrl: Scalars['String']['input'];
  name: Scalars['String']['input'];
  rank: Scalars['Float']['input'];
  rarity?: InputMaybe<Scalars['String']['input']>;
  xp: Scalars['Float']['input'];
};


export type MutationCreateCategoryArgs = {
  category: CategoryCreateInput;
};


export type MutationCreateCharmverseCommentArgs = {
  input: CreateCharmverseCommentInput;
};


export type MutationCreateCharmversePostArgs = {
  input: CreateCharmversePostInput;
};


export type MutationCreateIntegrationArgs = {
  name: Scalars['String']['input'];
};


export type MutationCreateIntegrationFeatureArgs = {
  integrationId: Scalars['ID']['input'];
  key: Scalars['String']['input'];
  name: Scalars['String']['input'];
};


export type MutationCreateLootBoxArgs = {
  input: CreateLootBoxInput;
};


export type MutationCreateLootBoxItemArgs = {
  id: Scalars['ID']['input'];
};


export type MutationCreateLootBoxXpItemArgs = {
  input: CreateLootBoxXpItemInput;
};


export type MutationCreateLootDropArgs = {
  input: LootDropInput;
};


export type MutationCreateQuestArgs = {
  quest: QuestInput;
};


export type MutationCreateRewardArgs = {
  input: InputCreateReward;
};


export type MutationCreateTaskArgs = {
  task: TaskInput;
};


export type MutationCreateTenantArgs = {
  name: Scalars['String']['input'];
};


export type MutationDeleteAchievementArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteCategoryArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteCharmverseCommentArgs = {
  input: DeleteCharmverseCommentInput;
};


export type MutationDeleteCharmversePostArgs = {
  input: DeleteCharmversePostInput;
};


export type MutationDeleteIntegrationFeatureArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteLootBoxArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteLootBoxItemArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteLootDropArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteQuestArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteTaskArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDisablePartnerArgs = {
  input: DisablePartnerReq;
};


export type MutationDisablePartnerProductArgs = {
  input: DisablePartnerProductReq;
};


export type MutationDisableRewardArgs = {
  input: InputDisableReward;
};


export type MutationDisconnectFromSocialNetworkArgs = {
  socialAccounts: SocialAccounts;
};


export type MutationEchoArgs = {
  data?: InputMaybe<Scalars['JSON']['input']>;
};


export type MutationEditCharmverseCommentArgs = {
  input: EditCharmverseCommentInput;
};


export type MutationEnsureLevel0ClaimableItemExistArgs = {
  forceNew?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationEquipInventoryItemsArgs = {
  payload: InventoryInput;
};


export type MutationInsertPartnerArgs = {
  input: InsertPartnerReq;
};


export type MutationInsertPartnerProductArgs = {
  input: InsertPartnerProductReq;
};


export type MutationMarkClaimableItemAsUsedArgs = {
  id: Scalars['ID']['input'];
};


export type MutationMintAvatarArgs = {
  baseSkinId: Scalars['Int']['input'];
};


export type MutationPatchPartnerArgs = {
  input: PatchPartnerReq;
};


export type MutationPatchPartnerProductArgs = {
  input: PatchPartnerProductReq;
};


export type MutationPublishQuestArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRedeemRewardArgs = {
  input: InputRedeemReward;
};


export type MutationRemoveAdminUserWalletAddressArgs = {
  walletAddress: Scalars['String']['input'];
};


export type MutationSaveAvatarArgs = {
  skinId: Scalars['String']['input'];
  tokenId: Scalars['Int']['input'];
};


export type MutationSaveQuestArgs = {
  achievements?: InputMaybe<Array<AchievementInput>>;
  quest: SaveQuestInput;
  tasks?: InputMaybe<Array<SaveQuestTaskInput>>;
};


export type MutationSetCategoriesOrderArgs = {
  categoryIds: Array<Scalars['String']['input']>;
};


export type MutationUpdateCategoryArgs = {
  category: CategoryUpdateInput;
  id: Scalars['String']['input'];
};


export type MutationUpdateCurrentUserSettingsArgs = {
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  discordUsername?: InputMaybe<Scalars['String']['input']>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  isCreditsOnboardingCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  isLootDropOnboardingCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  lineaGuild?: InputMaybe<Scalars['String']['input']>;
  solanaWalletAddress?: InputMaybe<Scalars['String']['input']>;
  telegramId?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateIntegrationFeatureArgs = {
  data: IntegrationFeatureInput;
  id: Scalars['ID']['input'];
};


export type MutationUpdateLootBoxNameArgs = {
  input: UpdateLootBoxNameInput;
};


export type MutationUpdateLootDropArgs = {
  id: Scalars['ID']['input'];
  input: UpdateLootDropInput;
};


export type MutationUpdatePartnerUserArgs = {
  featureId?: InputMaybe<Scalars['String']['input']>;
  partnerUserId: Scalars['ID']['input'];
};


export type MutationUpdateQuestArgs = {
  id: Scalars['ID']['input'];
  quest: QuestInput;
};


export type MutationUpdateRewardArgs = {
  input: InputUpdateReward;
};


export type MutationUpdateRewardIntegrationArgs = {
  input?: InputMaybe<InputUpdateRewardIntegration>;
};


export type MutationUpdateRewardRedeemedArgs = {
  input: InputUpdateRewardRedeemed;
};


export type MutationUpdateTaskArgs = {
  id: Scalars['ID']['input'];
  task: TaskInput;
};


export type MutationVoteOnCharmverseCommentArgs = {
  input: VoteOnCharmverseCommentInput;
};

export type Partner = {
  __typename?: 'Partner';
  contactPerson?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  emailAddress?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
  websiteUrl?: Maybe<Scalars['String']['output']>;
};

export type PatchPartnerProductReq = {
  contactPerson?: InputMaybe<Scalars['String']['input']>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  websiteUrl?: InputMaybe<Scalars['String']['input']>;
};

export type PatchPartnerProductRes = {
  __typename?: 'PatchPartnerProductRes';
  updated: Scalars['Boolean']['output'];
};

export type PatchPartnerReq = {
  contactPerson?: InputMaybe<Scalars['String']['input']>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  websiteUrl?: InputMaybe<Scalars['String']['input']>;
};

export type PatchPartnerRes = {
  __typename?: 'PatchPartnerRes';
  updated: Scalars['Boolean']['output'];
};

export type Post = {
  __typename?: 'Post';
  authors: Array<Maybe<User>>;
  commentCount: Scalars['Int']['output'];
  comments: Array<Maybe<PostComment>>;
  content: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  plainContent: Scalars['String']['output'];
  status?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type PostComment = {
  __typename?: 'PostComment';
  canDelete: Scalars['Boolean']['output'];
  children: Array<Maybe<PostComment>>;
  content: PostCommentContent;
  createdAt: Scalars['DateTime']['output'];
  createdBy?: Maybe<Scalars['String']['output']>;
  createdByUser?: Maybe<User>;
  downvotes: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  parent?: Maybe<PostComment>;
  upvotes: Scalars['Int']['output'];
};

export type PostCommentContent = {
  __typename?: 'PostCommentContent';
  markdown: Scalars['String']['output'];
  text: Scalars['String']['output'];
};

export type Product = {
  __typename?: 'Product';
  contactPerson?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  emailAddress?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  partnerId: Scalars['String']['output'];
  updatedAt: Scalars['String']['output'];
  websiteUrl?: Maybe<Scalars['String']['output']>;
};

export type Query = {
  __typename?: 'Query';
  /** @deprecated Will be removed in the future */
  achievement?: Maybe<Achievement>;
  /**
   * All achevements for the tenant
   * @deprecated Will be removed in the future
   */
  achievements: Array<Achievement>;
  /** Integrations where current wallet is an Admin */
  adminIntegrations: Array<Integration>;
  /** @deprecated Will be removed in the future */
  adminTenants: Array<Tenant>;
  /** @deprecated Will be removed in the future */
  badges: Array<Badge>;
  /** @deprecated Will be removed in the future */
  bounties: Array<Bounty>;
  /** @deprecated Will be removed in the future */
  bounty?: Maybe<Bounty>;
  campaigns: Array<Campaign>;
  categories: Array<Category>;
  category?: Maybe<Category>;
  /** @deprecated Will be removed in the future */
  craftingRecipes: Array<CraftingRecipe>;
  /** @deprecated Will be removed in the future */
  defaultTenant?: Maybe<Tenant>;
  /** @deprecated Will be removed in the future */
  forumPost?: Maybe<Post>;
  /** @deprecated Will be removed in the future */
  forumPosts: Array<Post>;
  getCreditsLeaderboard?: Maybe<Array<Maybe<CreditsLeaderboard>>>;
  getCreditsLeaderboardCurrentUser?: Maybe<CreditsLeaderboard>;
  getCreditsStats?: Maybe<CreditsStats>;
  getLootDrop?: Maybe<LootDrop>;
  getLootDrops: Array<LootDrop>;
  getPartner: GetPartnerRes;
  getPartnerProduct: GetPartnerProductRes;
  getReferralLeaderboard?: Maybe<Array<Maybe<ReferralLeaderboard>>>;
  getReferralLeaderboardCurrentUser?: Maybe<ReferralLeaderboard>;
  integrationFeature?: Maybe<IntegrationFeature>;
  /** All integrations */
  integrations: Array<Integration>;
  /** @deprecated Will be removed in the future */
  inventoryItems: Array<InventoryItems>;
  lineaLeaderboard: Array<LineaLeaderboardUser>;
  lineaLeaderboardCount: Scalars['Int']['output'];
  /** @deprecated Will be removed in the future */
  lootBox?: Maybe<LootBox>;
  /** @deprecated Will be removed in the future */
  lootBoxes: Array<LootBox>;
  /** @deprecated Temporarily deprecated */
  proposal?: Maybe<Post>;
  /** @deprecated Temporarily deprecated */
  proposals: Array<Post>;
  quest?: Maybe<Quest>;
  /** Quests for the selected tenant */
  quests: Array<Quest>;
  reward?: Maybe<Reward>;
  rewardIntegration?: Maybe<RewardIntegration>;
  rewards?: Maybe<Array<Maybe<Reward>>>;
  rewardsIntegration?: Maybe<Array<Maybe<RewardIntegration>>>;
  rewardsRedeemed?: Maybe<Array<Maybe<RewardRedeemed>>>;
  searchPartner: SearchPartnerRes;
  searchPartnerProduct: SearchPartnerProductRes;
  task?: Maybe<Task>;
  /** @deprecated Will be removed in the future */
  tenant?: Maybe<Tenant>;
  /** @deprecated Will be removed in the future */
  tenants: Array<Tenant>;
  timestamp: Scalars['DateTime']['output'];
  topUsers: Array<TopUser>;
  topUsersCount: Scalars['Int']['output'];
  user?: Maybe<User>;
  userConsentLootDrop: Scalars['Boolean']['output'];
  users: Array<User>;
  viewer?: Maybe<User>;
  viewerLineaLeaderboard?: Maybe<LineaLeaderboardUser>;
  viewerTopUser?: Maybe<TopUserData>;
};


export type QueryAchievementArgs = {
  id: Scalars['ID']['input'];
};


export type QueryBountyArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryCategoriesArgs = {
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  showLootDropCategories?: InputMaybe<Scalars['Boolean']['input']>;
  userCompletion?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryCategoryArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  urlSlug?: InputMaybe<Scalars['String']['input']>;
  userCompletion?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryForumPostArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetCreditsLeaderboardArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetLootDropArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  urlSlug?: InputMaybe<Scalars['String']['input']>;
  userCompletion?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryGetLootDropsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  showOnlyActive?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryGetPartnerArgs = {
  input: GetPartnerReq;
};


export type QueryGetPartnerProductArgs = {
  input: GetPartnerProductReq;
};


export type QueryGetReferralLeaderboardArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  minReferrals?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryIntegrationFeatureArgs = {
  id: Scalars['ID']['input'];
};


export type QueryInventoryItemsArgs = {
  tokenIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type QueryLineaLeaderboardArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryLootBoxArgs = {
  id: Scalars['ID']['input'];
};


export type QueryProposalArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryQuestArgs = {
  id: Scalars['ID']['input'];
};


export type QueryQuestsArgs = {
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  categoryName?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  minRank?: InputMaybe<Scalars['Float']['input']>;
  questName?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<QuestStatusQuery>;
  suggestedOnly?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryRewardArgs = {
  input: InputGetReward;
};


export type QueryRewardIntegrationArgs = {
  input: InputGetRewardIntegration;
};


export type QueryRewardsArgs = {
  input: InputSearchReward;
};


export type QueryRewardsIntegrationArgs = {
  input: InputSearchRewardIntegration;
};


export type QueryRewardsRedeemedArgs = {
  input: InputRewardRedeemed;
};


export type QuerySearchPartnerArgs = {
  input?: InputMaybe<SearchPartnerReq>;
};


export type QuerySearchPartnerProductArgs = {
  input: SearchPartnerProductReq;
};


export type QueryTaskArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTenantArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTopUsersArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryUserArgs = {
  id: Scalars['ID']['input'];
};


export type QueryUserConsentLootDropArgs = {
  lootDropId: Scalars['ID']['input'];
};


export type QueryUsersArgs = {
  filter?: InputMaybe<UserFilterInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type Quest = {
  __typename?: 'Quest';
  avatarUrl?: Maybe<Scalars['String']['output']>;
  categoryId?: Maybe<Scalars['ID']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  earnAchievement?: Maybe<Achievement>;
  earnAchievementId?: Maybe<Scalars['ID']['output']>;
  earnCredits?: Maybe<Scalars['Int']['output']>;
  /** The Rank will be earned by the user after completing the quest */
  earnRank: Scalars['Float']['output'];
  /** The XP will be earned by the user after completing the quest */
  earnXP: Scalars['Float']['output'];
  endTime?: Maybe<Scalars['DateTime']['output']>;
  entryAchievement?: Maybe<Achievement>;
  entryLevel?: Maybe<Scalars['Int']['output']>;
  entryRank: Scalars['Float']['output'];
  entryXP: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  instructions?: Maybe<Scalars['String']['output']>;
  /** User completed it or not, based on JWT token */
  isCompleted: Scalars['Boolean']['output'];
  isMission: Scalars['Boolean']['output'];
  isOrdered: Scalars['Boolean']['output'];
  isSuggessted: Scalars['Boolean']['output'];
  isSuggested: Scalars['Boolean']['output'];
  layout?: Maybe<Scalars['JSON']['output']>;
  name: Scalars['String']['output'];
  rewards: Array<Reward>;
  sortIndex?: Maybe<Scalars['Int']['output']>;
  startTime?: Maybe<Scalars['DateTime']['output']>;
  status: QuestStatus;
  tasks: Array<Task>;
};

export type QuestInput = {
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  categoryId: Scalars['ID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  earnAchievementId?: InputMaybe<Scalars['ID']['input']>;
  earnCredits?: InputMaybe<Scalars['Int']['input']>;
  /** The Rank will be earned by the user after completing the quest */
  earnRank: Scalars['Float']['input'];
  /** The XP will be earned by the user after completing the quest */
  earnXP: Scalars['Float']['input'];
  endTime?: InputMaybe<Scalars['DateTime']['input']>;
  entryRank: Scalars['Float']['input'];
  entryXP: Scalars['Float']['input'];
  instructions?: InputMaybe<Scalars['String']['input']>;
  isOrdered: Scalars['Boolean']['input'];
  isSuggested?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  sortIndex?: InputMaybe<Scalars['Int']['input']>;
  startTime?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<QuestStatus>;
};

export enum QuestStatus {
  Archived = 'ARCHIVED',
  Draft = 'DRAFT',
  Live = 'LIVE'
}

export enum QuestStatusQuery {
  All = 'ALL',
  Archived = 'ARCHIVED',
  Draft = 'DRAFT',
  Live = 'LIVE'
}

export enum RarityLevel {
  Ancient = 'ANCIENT',
  Common = 'COMMON',
  Epic = 'EPIC',
  Legendary = 'LEGENDARY',
  Overachiever = 'OVERACHIEVER',
  Rare = 'RARE',
  Uncommon = 'UNCOMMON'
}

export type ReferralLeaderboard = {
  __typename?: 'ReferralLeaderboard';
  avatarUrl?: Maybe<Scalars['String']['output']>;
  creditsAmount?: Maybe<Scalars['Float']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  legacyUserId?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  referralCode?: Maybe<Scalars['String']['output']>;
  referredUserIds?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  twitterUsername?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
  walletAddress?: Maybe<Scalars['String']['output']>;
};

export type Reward = {
  __typename?: 'Reward';
  active: Scalars['Boolean']['output'];
  campaignId?: Maybe<Scalars['String']['output']>;
  categoryId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  level?: Maybe<Scalars['Int']['output']>;
  lootDropId?: Maybe<Scalars['String']['output']>;
  questId?: Maybe<Scalars['String']['output']>;
  rewardIntegration?: Maybe<RewardIntegrationData>;
  rewardIntegrationId: Scalars['String']['output'];
  taskId?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  units: Scalars['Int']['output'];
  updatedAt: Scalars['String']['output'];
  videoUrl?: Maybe<Scalars['String']['output']>;
};

export type RewardIntegration = {
  __typename?: 'RewardIntegration';
  active: Scalars['Boolean']['output'];
  createdAt: Scalars['String']['output'];
  description: Scalars['String']['output'];
  distributedCount: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  readOnly: Scalars['Boolean']['output'];
  title: Scalars['String']['output'];
  totalBalance: Scalars['Int']['output'];
  totalLeft: Scalars['Int']['output'];
  updatedAt: Scalars['String']['output'];
};

export type RewardIntegrationData = {
  __typename?: 'RewardIntegrationData';
  active?: Maybe<Scalars['Boolean']['output']>;
  constraints?: Maybe<Constraints>;
  createdAt?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  distributedCount?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type RewardRedeemed = {
  __typename?: 'RewardRedeemed';
  acquiredAt: Scalars['String']['output'];
  additionalData?: Maybe<Scalars['JSON']['output']>;
  campaignId?: Maybe<Scalars['String']['output']>;
  categoryId?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  level?: Maybe<Scalars['Int']['output']>;
  lootDropId?: Maybe<Scalars['String']['output']>;
  questId?: Maybe<Scalars['String']['output']>;
  redeemed: Scalars['Boolean']['output'];
  rewardId: Scalars['String']['output'];
  rewardIntegration: RewardRedeemedIntegration;
  taskId?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  units: Scalars['Int']['output'];
  videoUrl?: Maybe<Scalars['String']['output']>;
};

export type RewardRedeemedIntegration = {
  __typename?: 'RewardRedeemedIntegration';
  additionalData?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['String']['output'];
};

export type SaveQuestInput = {
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  earnAchievementId?: InputMaybe<Scalars['ID']['input']>;
  earnCredits?: InputMaybe<Scalars['Int']['input']>;
  /** The Rank will be earned by the user after completing the quest */
  earnRank: Scalars['Float']['input'];
  /** The XP will be earned by the user after completing the quest */
  earnXP: Scalars['Float']['input'];
  endTime?: InputMaybe<Scalars['DateTime']['input']>;
  entryRank: Scalars['Float']['input'];
  entryXP: Scalars['Float']['input'];
  /** ID is optional and shoud be passed only for an existing quest. Keep it null for a new quest */
  id?: InputMaybe<Scalars['ID']['input']>;
  instructions?: InputMaybe<Scalars['String']['input']>;
  isOrdered: Scalars['Boolean']['input'];
  isSuggested: Scalars['Boolean']['input'];
  layout?: InputMaybe<Scalars['JSON']['input']>;
  name: Scalars['String']['input'];
  startTime?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<QuestStatus>;
};

export type SaveQuestTaskInput = {
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  earnAchievementId?: InputMaybe<Scalars['ID']['input']>;
  earnClaimableItems?: InputMaybe<Array<Scalars['String']['input']>>;
  earnCredits?: InputMaybe<Scalars['Int']['input']>;
  earnRank: Scalars['Float']['input'];
  earnXP: Scalars['Float']['input'];
  featureId: Scalars['ID']['input'];
  /** ID is optional and shoud be passed only for an existing task. Keep it null for a new task */
  id?: InputMaybe<Scalars['ID']['input']>;
  instructions?: InputMaybe<Scalars['String']['input']>;
  integrationId: Scalars['ID']['input'];
  layout?: InputMaybe<Scalars['JSON']['input']>;
  name: Scalars['String']['input'];
  order?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<QuestStatus>;
};

export type SearchPartnerProductReq = {
  name?: InputMaybe<Scalars['String']['input']>;
  partnerId: Scalars['ID']['input'];
};

export type SearchPartnerProductRes = {
  __typename?: 'SearchPartnerProductRes';
  product: Array<Maybe<Product>>;
};

export type SearchPartnerReq = {
  name?: InputMaybe<Scalars['String']['input']>;
};

export type SearchPartnerRes = {
  __typename?: 'SearchPartnerRes';
  partner: Array<Maybe<Partner>>;
};

export enum SocialAccounts {
  Charmverse = 'CHARMVERSE',
  Discord = 'DISCORD',
  Twitter = 'TWITTER'
}

export type SolanaWallet = {
  __typename?: 'SolanaWallet';
  address?: Maybe<Scalars['String']['output']>;
};

export type Task = {
  __typename?: 'Task';
  actionType?: Maybe<TaskActionType>;
  avatarUrl?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  earnAchievementId?: Maybe<Scalars['ID']['output']>;
  earnClaimableItems: Array<Scalars['String']['output']>;
  earnCredits?: Maybe<Scalars['Int']['output']>;
  earnRank: Scalars['Float']['output'];
  earnXP: Scalars['Float']['output'];
  featureId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  inputData?: Maybe<Scalars['String']['output']>;
  instructions?: Maybe<Scalars['String']['output']>;
  integrationFeature?: Maybe<IntegrationFeature>;
  integrationId: Scalars['ID']['output'];
  isCompleted: Scalars['Boolean']['output'];
  isRequired: Scalars['Boolean']['output'];
  layout?: Maybe<Scalars['JSON']['output']>;
  name: Scalars['String']['output'];
  order?: Maybe<Scalars['Int']['output']>;
  showManualCheckButton: Scalars['Boolean']['output'];
  status: QuestStatus;
};

export enum TaskActionType {
  AddEmail = 'ADD_EMAIL',
  AddUsername = 'ADD_USERNAME',
  DiscordJoin = 'DISCORD_JOIN',
  DiscordLogin = 'DISCORD_LOGIN',
  Hyperplay = 'HYPERPLAY',
  LineaGuildPicker = 'LINEA_GUILD_PICKER',
  Scid = 'SCID',
  SolanaWallet = 'SOLANA_WALLET',
  Stake = 'STAKE',
  TelegramConnect = 'TELEGRAM_CONNECT',
  TwitterLogin = 'TWITTER_LOGIN',
  Typeform = 'TYPEFORM',
  VisitSite = 'VISIT_SITE'
}

export type TaskInput = {
  actionType?: InputMaybe<TaskActionType>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  earnAchievementId?: InputMaybe<Scalars['ID']['input']>;
  earnClaimableItems?: InputMaybe<Array<Scalars['String']['input']>>;
  earnCredits?: InputMaybe<Scalars['Int']['input']>;
  earnRank: Scalars['Float']['input'];
  earnXP: Scalars['Float']['input'];
  endTime?: InputMaybe<Scalars['DateTime']['input']>;
  featureId: Scalars['ID']['input'];
  inputData?: InputMaybe<Scalars['String']['input']>;
  instructions?: InputMaybe<Scalars['String']['input']>;
  integrationId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  order?: InputMaybe<Scalars['Int']['input']>;
  questId: Scalars['ID']['input'];
  startTime?: InputMaybe<Scalars['DateTime']['input']>;
  status?: InputMaybe<QuestStatus>;
};

export type Telegram = {
  __typename?: 'Telegram';
  id?: Maybe<Scalars['String']['output']>;
};

export type Tenant = {
  __typename?: 'Tenant';
  adminWalletAddressList: Array<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  quests: Array<Quest>;
};


export type TenantQuestsArgs = {
  minRank?: InputMaybe<Scalars['Float']['input']>;
  status?: InputMaybe<QuestStatus>;
};

export type TopUser = {
  __typename?: 'TopUser';
  avatarUrl?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  position: Scalars['Int']['output'];
  username?: Maybe<Scalars['String']['output']>;
  walletAddress?: Maybe<Scalars['String']['output']>;
  xp?: Maybe<Scalars['Float']['output']>;
};

export type TopUserData = {
  __typename?: 'TopUserData';
  position: Scalars['Int']['output'];
};

export type TransactionHistoryItem = {
  __typename?: 'TransactionHistoryItem';
  badges: Array<Badge>;
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  materials: Array<CraftingMaterial>;
  txAddress?: Maybe<Scalars['String']['output']>;
  txLink?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  value: Scalars['Int']['output'];
};

export enum TransactionHistoryType {
  OffChain = 'OFF_CHAIN',
  OnChain = 'ON_CHAIN'
}

export type UpdateLootBoxNameInput = {
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type UpdateLootBoxPayload = {
  __typename?: 'UpdateLootBoxPayload';
  lootBox: LootBox;
};

export type UpdateLootDropInput = {
  categoriesIds?: InputMaybe<Array<Scalars['String']['input']>>;
  coverImage?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  endTime?: InputMaybe<Scalars['DateTime']['input']>;
  multiplierPerCategory?: InputMaybe<Scalars['Int']['input']>;
  multiplierPerLevel?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  rewardPoolInfo?: InputMaybe<Scalars['String']['input']>;
  startTime?: InputMaybe<Scalars['DateTime']['input']>;
  urlSlug?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  __typename?: 'User';
  /** User achevements */
  achievements: Array<Achievement>;
  avatarMintTx?: Maybe<Scalars['String']['output']>;
  avatarMintUrl?: Maybe<Scalars['String']['output']>;
  avatarUrl?: Maybe<Scalars['String']['output']>;
  badgeCount: Scalars['Int']['output'];
  badges: Array<Badge>;
  charmverseUserId?: Maybe<Scalars['ID']['output']>;
  claimableItems: Array<ClaimableItem>;
  /** @deprecated No longer in use due to inventory changes. */
  craftingMaterials?: Maybe<Array<Maybe<CraftingMaterial>>>;
  createdAt: Scalars['DateTime']['output'];
  currentLevelInfo: CurrentLevelInfo;
  customData?: Maybe<CustomData>;
  discordUserId?: Maybe<Scalars['String']['output']>;
  discordUsername?: Maybe<Scalars['String']['output']>;
  emailAddress?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  githubUsername?: Maybe<Scalars['String']['output']>;
  hasCompletedTutorial: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  inventoryItems?: Maybe<Array<Maybe<InventoryItem>>>;
  inventorySlots?: Maybe<Array<Maybe<InventorySlot>>>;
  isAvatarMinted: Scalars['Boolean']['output'];
  isCreditsOnboardingCompleted?: Maybe<Scalars['Boolean']['output']>;
  isLootDropOnboardingCompleted?: Maybe<Scalars['Boolean']['output']>;
  isOnboardingCompleted: Scalars['Boolean']['output'];
  lastAchievedLevel?: Maybe<Scalars['Int']['output']>;
  lastLevelUp?: Maybe<UserLevelUp>;
  lastName?: Maybe<Scalars['String']['output']>;
  level: Scalars['Float']['output'];
  levelInfo: LevelInfo;
  rank: Scalars['Float']['output'];
  referralCode?: Maybe<Scalars['String']['output']>;
  referrerCode?: Maybe<Scalars['String']['output']>;
  skinId?: Maybe<Scalars['String']['output']>;
  tokenId?: Maybe<Scalars['Int']['output']>;
  transactionHistory: Array<TransactionHistoryItem>;
  twitterDisplayName?: Maybe<Scalars['String']['output']>;
  twitterUsername?: Maybe<Scalars['String']['output']>;
  userApplicationType?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
  walletAddress: Scalars['String']['output'];
  xp: Scalars['Float']['output'];
};


export type UserInventoryItemsArgs = {
  equippedOnly?: InputMaybe<Scalars['Boolean']['input']>;
  isDna?: InputMaybe<Scalars['Boolean']['input']>;
  tokenIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};


export type UserTransactionHistoryArgs = {
  type?: InputMaybe<TransactionHistoryType>;
};

export type UserFilterInput = {
  discordUserId?: InputMaybe<Scalars['String']['input']>;
  discordUsername?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  walletAddress?: InputMaybe<Scalars['String']['input']>;
};

export type UserLevelUp = {
  __typename?: 'UserLevelUp';
  assetUrl: Scalars['String']['output'];
  claimableItem?: Maybe<ClaimableItem>;
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  itemCount?: Maybe<Scalars['Int']['output']>;
  level: Scalars['Int']['output'];
  privilegesEarned: Array<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export enum VoteEnum {
  Downvote = 'DOWNVOTE',
  Remove = 'REMOVE',
  Upvote = 'UPVOTE'
}

export type VoteOnCharmverseCommentInput = {
  commentId: Scalars['ID']['input'];
  proposalId?: InputMaybe<Scalars['ID']['input']>;
  vote: VoteEnum;
};

export type VoteOnCharmverseCommentPayload = {
  __typename?: 'VoteOnCharmverseCommentPayload';
  comment: PostComment;
};

export type XpLootBoxItem = {
  __typename?: 'XPLootBoxItem';
  amount: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type GetCategorySlugQueryVariables = Exact<{
  categoryId: Scalars['ID']['input'];
}>;


export type GetCategorySlugQuery = { __typename?: 'Query', category?: { __typename?: 'Category', urlSlug?: string | null } | null };

export type GetCreditsStatsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCreditsStatsQuery = { __typename?: 'Query', getCreditsStats?: { __typename?: 'CreditsStats', amountHyperPlayCredits: number, maxQuestCredits: number, maxHyperPlayCredits: number, amountUserCredits: number, amountReferralCredits: number, amountQuestCredits: number } | null };

export type GetCreditsLeaderboardQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetCreditsLeaderboardQuery = { __typename?: 'Query', getCreditsLeaderboard?: Array<{ __typename?: 'CreditsLeaderboard', avatarUrl?: string | null, creditsAmount?: number | null, firstName?: string | null, legacyUserId?: string | null, position?: number | null, walletAddress?: string | null, username?: string | null } | null> | null };

export type GetCreditsLeaderboardCurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCreditsLeaderboardCurrentUserQuery = { __typename?: 'Query', getCreditsLeaderboardCurrentUser?: { __typename?: 'CreditsLeaderboard', avatarUrl?: string | null, creditsAmount?: number | null, firstName?: string | null, legacyUserId?: string | null, position?: number | null, username?: string | null, walletAddress?: string | null } | null };

export type GetReferralLeaderboardQueryVariables = Exact<{
  offset?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  minReferrals?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetReferralLeaderboardQuery = { __typename?: 'Query', getReferralLeaderboard?: Array<{ __typename?: 'ReferralLeaderboard', avatarUrl?: string | null, creditsAmount?: number | null, firstName?: string | null, legacyUserId?: string | null, position?: number | null, referralCode?: string | null, username?: string | null, walletAddress?: string | null, referredUserIds?: Array<string | null> | null, twitterUsername?: string | null } | null> | null };

export type GetReferralLeaderboardCurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetReferralLeaderboardCurrentUserQuery = { __typename?: 'Query', getReferralLeaderboardCurrentUser?: { __typename?: 'ReferralLeaderboard', avatarUrl?: string | null, creditsAmount?: number | null, firstName?: string | null, legacyUserId?: string | null, position?: number | null, referralCode?: string | null, referredUserIds?: Array<string | null> | null, username?: string | null, walletAddress?: string | null } | null };

export type CreateReferralCodeMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateReferralCodeMutation = { __typename?: 'Mutation', createReferralCode?: boolean | null };

export type GetTransactionHistoryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTransactionHistoryQuery = { __typename?: 'Query', viewer?: { __typename?: 'User', id: string, transactionHistory: Array<{ __typename?: 'TransactionHistoryItem', id: string, type: string, description: string, value: number, txLink?: string | null, txAddress?: string | null, createdAt: any, badges: Array<{ __typename?: 'Badge', id: string, name: string }> }> } | null };

export type LevelUpMutationVariables = Exact<{ [key: string]: never; }>;


export type LevelUpMutation = { __typename?: 'Mutation', mintLevelUp: boolean };

export type GetBadgesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBadgesQuery = { __typename?: 'Query', viewer?: { __typename?: 'User', id: string, badges: Array<{ __typename?: 'Badge', id: string, name: string, imageUrl: string, createdAt: any }> } | null };

export type UpdateRewardRedeemedMutationVariables = Exact<{
  input: InputUpdateRewardRedeemed;
}>;


export type UpdateRewardRedeemedMutation = { __typename?: 'Mutation', updateRewardRedeemed: boolean };

export type GetNextCategorySuggestedQueryVariables = Exact<{ [key: string]: never; }>;


export type GetNextCategorySuggestedQuery = { __typename?: 'Query', viewer?: { __typename?: 'User', currentLevelInfo: { __typename?: 'CurrentLevelInfo', nextCategorySuggested?: { __typename?: 'Category', id: string, imageUrl?: string | null, name: string, description?: string | null } | null } } | null };

export type GetLeaderboardQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
}>;


export type GetLeaderboardQuery = { __typename?: 'Query', topUsersCount: number, topUsers: Array<{ __typename?: 'TopUser', id: string, avatarUrl?: string | null, username?: string | null, firstName?: string | null, walletAddress?: string | null, xp?: number | null, position: number }> };

export type GetUserLeaderboardQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserLeaderboardQuery = { __typename?: 'Query', viewer?: { __typename?: 'User', id: string, avatarUrl?: string | null, username?: string | null, firstName?: string | null, walletAddress: string, xp: number } | null, viewerTopUser?: { __typename?: 'TopUserData', position: number } | null };

export type GetLootDropsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLootDropsQuery = { __typename?: 'Query', getLootDrops: Array<{ __typename?: 'LootDrop', id: string, coverImage: string, description: string, endTime: any, multiplierPerCategory: number, multiplierPerLevel: number, name: string, participantCount: number, totalMultiplier: number, startTime: any, rewardPoolInfo: string, completionPercentage?: number | null, urlSlug: string, rewards?: Array<{ __typename?: 'Reward', title: string, units: number }> | null }> };

export type GetLootDropQueryVariables = Exact<{
  urlSlug?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetLootDropQuery = { __typename?: 'Query', getLootDrop?: { __typename?: 'LootDrop', id: string, categoriesIds: Array<string>, createdAt: any, updatedAt: any, name: string, completionPercentage?: number | null, description: string, coverImage: string, startTime: any, endTime: any, multiplier?: number | null, multiplierPerCategory: number, multiplierPerLevel: number, totalMultiplier: number, participantCount: number, urlSlug: string, rewardPoolInfo: string, categories?: Array<{ __typename?: 'Category', id: string, name: string, description?: string | null, startTime?: any | null, endTime?: any | null, imageUrl?: string | null, isActive?: boolean | null, campaigns: Array<string>, isFeatured?: boolean | null, urlSlug?: string | null, completionPercentage?: number | null }> | null, rewards?: Array<{ __typename?: 'Reward', id: string, title: string, description?: string | null, imageUrl?: string | null, active: boolean, units: number, createdAt: string, rewardIntegrationId: string, updatedAt: string, rewardIntegration?: { __typename?: 'RewardIntegrationData', constraints?: { __typename?: 'Constraints', maxUnitDistributionLimit?: number | null } | null } | null }> | null } | null };

export type GetUserConsentLootDropQueryVariables = Exact<{
  lootDropId: Scalars['ID']['input'];
}>;


export type GetUserConsentLootDropQuery = { __typename?: 'Query', userConsentLootDrop: boolean };

export type ConsentLootDropTermsMutationVariables = Exact<{
  lootDropId: Scalars['ID']['input'];
}>;


export type ConsentLootDropTermsMutation = { __typename?: 'Mutation', consentLootDropTerms: boolean };

export type AddUserReferralMutationVariables = Exact<{
  userId: Scalars['String']['input'];
  referralCode: Scalars['String']['input'];
}>;


export type AddUserReferralMutation = { __typename?: 'Mutation', addUserReferral: boolean };

export type SaveAvatarMutationVariables = Exact<{
  tokenId: Scalars['Int']['input'];
  skinId: Scalars['String']['input'];
}>;


export type SaveAvatarMutation = { __typename?: 'Mutation', saveAvatar: { __typename?: 'User', id: string, avatarUrl?: string | null, isAvatarMinted: boolean, isOnboardingCompleted: boolean, skinId?: string | null, tokenId?: number | null } };

export type MintAvatarMutationVariables = Exact<{
  baseSkinId: Scalars['Int']['input'];
}>;


export type MintAvatarMutation = { __typename?: 'Mutation', mintAvatar: boolean };

export type CompleteUserOnboardingMutationVariables = Exact<{ [key: string]: never; }>;


export type CompleteUserOnboardingMutation = { __typename?: 'Mutation', completeUserOnboarding?: boolean | null };

export type GetProposalQueryVariables = Exact<{
  proposalId?: InputMaybe<Scalars['ID']['input']>;
}>;


export type GetProposalQuery = { __typename?: 'Query', proposal?: { __typename?: 'Post', content: string, commentCount: number, createdAt: any, id: string, plainContent: string, title: string, url: string, status?: string | null, comments: Array<{ __typename?: 'PostComment', id: string, createdAt: any, createdBy?: string | null, canDelete: boolean, downvotes: number, upvotes: number, content: { __typename?: 'PostCommentContent', markdown: string }, createdByUser?: { __typename?: 'User', id: string, avatarUrl?: string | null, walletAddress: string } | null } | null> } | null };

export type GetProposalsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProposalsQuery = { __typename?: 'Query', proposals: Array<{ __typename?: 'Post', content: string, createdAt: any, id: string, plainContent: string, commentCount: number, url: string, title: string, status?: string | null }> };

export type CreateCharmverseMemberMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateCharmverseMemberMutation = { __typename?: 'Mutation', createCharmverseMember: { __typename?: 'CreateCharmverseMemberPayload', user: { __typename?: 'User', id: string, charmverseUserId?: string | null } } };

export type CreateCharmverseCommentMutationVariables = Exact<{
  input: CreateCharmverseCommentInput;
}>;


export type CreateCharmverseCommentMutation = { __typename?: 'Mutation', createCharmverseComment: { __typename?: 'CreateCharmverseCommentPayload', comment: { __typename?: 'PostComment', id: string } } };

export type VoteOnCharmverseCommentMutationVariables = Exact<{
  input: VoteOnCharmverseCommentInput;
}>;


export type VoteOnCharmverseCommentMutation = { __typename?: 'Mutation', voteOnCharmverseComment: { __typename?: 'VoteOnCharmverseCommentPayload', comment: { __typename?: 'PostComment', id: string, upvotes: number, downvotes: number } } };

export type DeleteCharmverseCommentMutationVariables = Exact<{
  input: DeleteCharmverseCommentInput;
}>;


export type DeleteCharmverseCommentMutation = { __typename?: 'Mutation', deleteCharmverseComment: { __typename?: 'DeleteCharmverseCommentPayload', comment: { __typename?: 'PostComment', id: string } } };

export type EditCharmverseCommentMutationVariables = Exact<{
  input: EditCharmverseCommentInput;
}>;


export type EditCharmverseCommentMutation = { __typename?: 'Mutation', editCharmverseComment: { __typename?: 'EditCharmverseCommentPayload', comment: { __typename?: 'PostComment', id: string, createdAt: any, createdBy?: string | null, canDelete: boolean, downvotes: number, upvotes: number, content: { __typename?: 'PostCommentContent', markdown: string }, createdByUser?: { __typename?: 'User', id: string, avatarUrl?: string | null, walletAddress: string } | null } } };

export type CommentFieldsFragment = { __typename?: 'PostComment', id: string, createdAt: any, createdBy?: string | null, canDelete: boolean, downvotes: number, upvotes: number, content: { __typename?: 'PostCommentContent', markdown: string }, createdByUser?: { __typename?: 'User', id: string, avatarUrl?: string | null, walletAddress: string } | null };

export type GetCategoriesQueryVariables = Exact<{
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  userCompletion?: InputMaybe<Scalars['Boolean']['input']>;
  showLootDropCategories?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GetCategoriesQuery = { __typename?: 'Query', categories: Array<{ __typename?: 'Category', id: string, urlSlug?: string | null, name: string, description?: string | null, backgroundUrl?: string | null, imageUrl?: string | null, isFeatured?: boolean | null, isActive?: boolean | null, campaigns: Array<string>, completionPercentage?: number | null, totalCredits?: number | null, totalXP?: number | null }> };

export type GetCampaignsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCampaignsQuery = { __typename?: 'Query', campaigns: Array<{ __typename?: 'Campaign', key: string, name?: string | null }> };

export type GetQuestQueryVariables = Exact<{
  questId: Scalars['ID']['input'];
}>;


export type GetQuestQuery = { __typename?: 'Query', quest?: { __typename?: 'Quest', description?: string | null, avatarUrl?: string | null, name: string, isOrdered: boolean, instructions?: string | null, id: string, entryXP: number, entryRank: number, earnXP: number, earnCredits?: number | null, earnRank: number, isCompleted: boolean, earnAchievement?: { __typename?: 'Achievement', id: string, imageUrl: string, name: string, xp: number } | null, tasks: Array<{ __typename?: 'Task', id: string, featureId: string, name: string, description?: string | null, instructions?: string | null, avatarUrl?: string | null, earnXP: number, earnCredits?: number | null, actionType?: TaskActionType | null, isRequired: boolean, isCompleted: boolean, showManualCheckButton: boolean, status: QuestStatus, inputData?: string | null, integrationFeature?: { __typename?: 'IntegrationFeature', id: string, integrationKey?: string | null } | null }> } | null };

export type CheckTaskCompletion2MutationVariables = Exact<{
  questId: Scalars['ID']['input'];
  taskId: Scalars['ID']['input'];
}>;


export type CheckTaskCompletion2Mutation = { __typename?: 'Mutation', checkTaskCompletion2: string };

export type UpdatePartnerUserMutationVariables = Exact<{
  partnerUserId: Scalars['ID']['input'];
  featureId?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdatePartnerUserMutation = { __typename?: 'Mutation', updatePartnerUser: boolean };

export type CheckTaskCompletionMutationVariables = Exact<{
  questId: Scalars['ID']['input'];
  taskId: Scalars['ID']['input'];
}>;


export type CheckTaskCompletionMutation = { __typename?: 'Mutation', checkTaskCompletion?: boolean | null };

export type DisconnectSocialNetworkMutationVariables = Exact<{
  socialAccounts: SocialAccounts;
}>;


export type DisconnectSocialNetworkMutation = { __typename?: 'Mutation', disconnectFromSocialNetwork: { __typename?: 'User', id: string, charmverseUserId?: string | null, discordUserId?: string | null, discordUsername?: string | null, twitterUsername?: string | null } };

export type FinishTutorialMutationVariables = Exact<{ [key: string]: never; }>;


export type FinishTutorialMutation = { __typename?: 'Mutation', completeTutorial?: boolean | null };

export type UpdateCurrentUserImageMutationVariables = Exact<{
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateCurrentUserImageMutation = { __typename?: 'Mutation', updateCurrentUserSettings: { __typename?: 'User', id: string, avatarUrl?: string | null } };

export type GetCategoryBySlugQueryVariables = Exact<{
  urlSlug: Scalars['String']['input'];
  userCompletion?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GetCategoryBySlugQuery = { __typename?: 'Query', category?: { __typename?: 'Category', id: string, name: string, description?: string | null, urlSlug?: string | null, imageUrl?: string | null, backgroundUrl?: string | null, startTime?: any | null, endTime?: any | null, campaigns: Array<string>, completionPercentage?: number | null, rewards?: Array<{ __typename?: 'Reward', id: string, units: number, title: string, description?: string | null }> | null } | null };

export type GetCategoryQuestsQueryVariables = Exact<{
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  categoryName?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetCategoryQuestsQuery = { __typename?: 'Query', quests: Array<{ __typename?: 'Quest', id: string, name: string, description?: string | null, avatarUrl?: string | null, isCompleted: boolean, status: QuestStatus, instructions?: string | null, entryRank: number, entryXP: number, earnXP: number, earnCredits?: number | null, earnRank: number, tasks: Array<{ __typename?: 'Task', id: string, featureId: string, name: string, description?: string | null, instructions?: string | null, avatarUrl?: string | null, earnXP: number, earnCredits?: number | null, actionType?: TaskActionType | null, isRequired: boolean, isCompleted: boolean, inputData?: string | null, showManualCheckButton: boolean, status: QuestStatus, integrationFeature?: { __typename?: 'IntegrationFeature', id: string, integrationKey?: string | null } | null }>, rewards: Array<{ __typename?: 'Reward', title: string, description?: string | null, units: number }> }> };

export type GetRewardsRedeemedQueryVariables = Exact<{
  input: InputRewardRedeemed;
}>;


export type GetRewardsRedeemedQuery = { __typename?: 'Query', rewardsRedeemed?: Array<{ __typename?: 'RewardRedeemed', id: string, imageUrl?: string | null, title: string, units: number, redeemed: boolean, lootDropId?: string | null, description?: string | null, rewardIntegration: { __typename?: 'RewardRedeemedIntegration', additionalData?: any | null, id: string } } | null> | null };

export type RedeemRewardMutationVariables = Exact<{
  input: InputRedeemReward;
}>;


export type RedeemRewardMutation = { __typename?: 'Mutation', redeemReward: any };

export type AddOnChainTransactionHistoryMutationVariables = Exact<{
  txAddress: Scalars['String']['input'];
  contractAddress: Scalars['String']['input'];
  chainId: Scalars['Int']['input'];
  description: Scalars['String']['input'];
  txLink: Scalars['String']['input'];
}>;


export type AddOnChainTransactionHistoryMutation = { __typename?: 'Mutation', addOnChainTransactionHistory: boolean };

export type GetUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserQuery = { __typename?: 'Query', viewer?: { __typename?: 'User', id: string, tokenId?: number | null, walletAddress: string, discordUsername?: string | null, emailAddress?: string | null, avatarUrl?: string | null, firstName?: string | null, lastName?: string | null, username?: string | null, githubUsername?: string | null, twitterUsername?: string | null, charmverseUserId?: string | null, hasCompletedTutorial: boolean, isOnboardingCompleted: boolean, isLootDropOnboardingCompleted?: boolean | null, isAvatarMinted: boolean, referralCode?: string | null, referrerCode?: string | null, skinId?: string | null, avatarMintUrl?: string | null, avatarMintTx?: string | null, level: number, xp: number, rank: number, isCreditsOnboardingCompleted?: boolean | null, currentLevelInfo: { __typename?: 'CurrentLevelInfo', completedRequiredQuests: number, totalLevelRequiredQuests: number, totalLevelRequiredXp?: number | null, userLevelXp: number, minXpToNextLevel?: number | null }, customData?: { __typename?: 'CustomData', solanaWallet?: { __typename?: 'SolanaWallet', address?: string | null } | null, telegram?: { __typename?: 'Telegram', id?: string | null } | null, linea?: { __typename?: 'Linea', guild?: string | null } | null } | null } | null };

export type UpdateCurrentUserSettingsMutationVariables = Exact<{
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  discordUsername?: InputMaybe<Scalars['String']['input']>;
  emailAddress?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
  solanaWalletAddress?: InputMaybe<Scalars['String']['input']>;
  isLootDropOnboardingCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  isCreditsOnboardingCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  telegramId?: InputMaybe<Scalars['String']['input']>;
  lineaGuild?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateCurrentUserSettingsMutation = { __typename?: 'Mutation', updateCurrentUserSettings: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null, username?: string | null, emailAddress?: string | null, discordUsername?: string | null, isOnboardingCompleted: boolean, isLootDropOnboardingCompleted?: boolean | null, isAvatarMinted: boolean, isCreditsOnboardingCompleted?: boolean | null, customData?: { __typename?: 'CustomData', solanaWallet?: { __typename?: 'SolanaWallet', address?: string | null } | null, telegram?: { __typename?: 'Telegram', id?: string | null } | null, linea?: { __typename?: 'Linea', guild?: string | null } | null } | null } };

export type GetAllDnaTraitsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllDnaTraitsQuery = { __typename?: 'Query', viewer?: { __typename?: 'User', inventoryItems?: Array<{ __typename?: 'InventoryItem', id: string, imageUrl: string, glbUrl: string, slotType?: number | null, isEquipped?: boolean | null, soulbound?: boolean | null, uri?: string | null, chain?: { __typename?: 'Chain', tokenId: number, address: string } | null } | null> | null } | null };

export type GetEquippedDnaItemsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEquippedDnaItemsQuery = { __typename?: 'Query', viewer?: { __typename?: 'User', inventoryItems?: Array<{ __typename?: 'InventoryItem', id: string, imageUrl: string, glbUrl: string, slotType?: number | null, isEquipped?: boolean | null, soulbound?: boolean | null, uri?: string | null, chain?: { __typename?: 'Chain', tokenId: number, address: string } | null } | null> | null } | null };

export type GetInventoryItemsByIdsQueryVariables = Exact<{
  tokenIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
}>;


export type GetInventoryItemsByIdsQuery = { __typename?: 'Query', viewer?: { __typename?: 'User', inventoryItems?: Array<{ __typename?: 'InventoryItem', id: string, slotType?: number | null, isEquipped?: boolean | null, soulbound?: boolean | null, uri?: string | null, imageUrl: string, glbUrl: string, chain?: { __typename?: 'Chain', tokenId: number, address: string } | null } | null> | null } | null };

export type GetInventoryItemsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetInventoryItemsQuery = { __typename?: 'Query', viewer?: { __typename?: 'User', id: string, skinId?: string | null, inventoryItems?: Array<{ __typename?: 'InventoryItem', id: string, imageUrl: string, glbUrl: string, isEquipped?: boolean | null, slotType?: number | null, count: number, soulbound?: boolean | null, chain?: { __typename?: 'Chain', tokenId: number, address: string } | null } | null> | null } | null };

export type EquipInventoryItemsMutationVariables = Exact<{
  payload: InventoryInput;
}>;


export type EquipInventoryItemsMutation = { __typename?: 'Mutation', equipInventoryItems?: boolean | null };


export const CommentFieldsFragmentDoc = `
    fragment CommentFields on PostComment {
  id
  createdAt
  createdBy
  canDelete
  downvotes
  upvotes
  content {
    markdown
  }
  createdByUser {
    id
    avatarUrl
    walletAddress
  }
}
    `;
export const GetCategorySlugDocument = `
    query GetCategorySlug($categoryId: ID!) {
  category(id: $categoryId) {
    urlSlug
  }
}
    `;

export const useGetCategorySlugQuery = <
      TData = GetCategorySlugQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetCategorySlugQueryVariables,
      options?: Omit<UseQueryOptions<GetCategorySlugQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<GetCategorySlugQuery, TError, TData>['queryKey'] },
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetCategorySlugQuery, TError, TData>(
      {
    queryKey: ['GetCategorySlug', variables],
    queryFn: fetcher<GetCategorySlugQuery, GetCategorySlugQueryVariables>(client, GetCategorySlugDocument, variables, headers),
    ...options
  }
    )};

export const GetCreditsStatsDocument = `
    query GetCreditsStats {
  getCreditsStats {
    amountHyperPlayCredits
    maxQuestCredits
    maxHyperPlayCredits
    amountUserCredits
    amountReferralCredits
    amountQuestCredits
  }
}
    `;

export const useGetCreditsStatsQuery = <
      TData = GetCreditsStatsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetCreditsStatsQueryVariables,
      options?: Omit<UseQueryOptions<GetCreditsStatsQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<GetCreditsStatsQuery, TError, TData>['queryKey'] },
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetCreditsStatsQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['GetCreditsStats'] : ['GetCreditsStats', variables],
    queryFn: fetcher<GetCreditsStatsQuery, GetCreditsStatsQueryVariables>(client, GetCreditsStatsDocument, variables, headers),
    ...options
  }
    )};

export const GetCreditsLeaderboardDocument = `
    query GetCreditsLeaderboard($offset: Int, $limit: Int) {
  getCreditsLeaderboard(offset: $offset, limit: $limit) {
    avatarUrl
    creditsAmount
    firstName
    legacyUserId
    position
    walletAddress
    username
  }
}
    `;

export const useGetCreditsLeaderboardQuery = <
      TData = GetCreditsLeaderboardQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetCreditsLeaderboardQueryVariables,
      options?: Omit<UseQueryOptions<GetCreditsLeaderboardQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<GetCreditsLeaderboardQuery, TError, TData>['queryKey'] },
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetCreditsLeaderboardQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['GetCreditsLeaderboard'] : ['GetCreditsLeaderboard', variables],
    queryFn: fetcher<GetCreditsLeaderboardQuery, GetCreditsLeaderboardQueryVariables>(client, GetCreditsLeaderboardDocument, variables, headers),
    ...options
  }
    )};

export const GetCreditsLeaderboardCurrentUserDocument = `
    query GetCreditsLeaderboardCurrentUser {
  getCreditsLeaderboardCurrentUser {
    avatarUrl
    creditsAmount
    firstName
    legacyUserId
    position
    username
    walletAddress
  }
}
    `;

export const useGetCreditsLeaderboardCurrentUserQuery = <
      TData = GetCreditsLeaderboardCurrentUserQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetCreditsLeaderboardCurrentUserQueryVariables,
      options?: Omit<UseQueryOptions<GetCreditsLeaderboardCurrentUserQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<GetCreditsLeaderboardCurrentUserQuery, TError, TData>['queryKey'] },
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetCreditsLeaderboardCurrentUserQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['GetCreditsLeaderboardCurrentUser'] : ['GetCreditsLeaderboardCurrentUser', variables],
    queryFn: fetcher<GetCreditsLeaderboardCurrentUserQuery, GetCreditsLeaderboardCurrentUserQueryVariables>(client, GetCreditsLeaderboardCurrentUserDocument, variables, headers),
    ...options
  }
    )};

export const GetReferralLeaderboardDocument = `
    query GetReferralLeaderboard($offset: Int, $limit: Int, $minReferrals: Int) {
  getReferralLeaderboard(
    offset: $offset
    limit: $limit
    minReferrals: $minReferrals
  ) {
    avatarUrl
    creditsAmount
    firstName
    legacyUserId
    position
    referralCode
    username
    walletAddress
    referredUserIds
    twitterUsername
    referralCode
  }
}
    `;

export const useGetReferralLeaderboardQuery = <
      TData = GetReferralLeaderboardQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetReferralLeaderboardQueryVariables,
      options?: Omit<UseQueryOptions<GetReferralLeaderboardQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<GetReferralLeaderboardQuery, TError, TData>['queryKey'] },
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetReferralLeaderboardQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['GetReferralLeaderboard'] : ['GetReferralLeaderboard', variables],
    queryFn: fetcher<GetReferralLeaderboardQuery, GetReferralLeaderboardQueryVariables>(client, GetReferralLeaderboardDocument, variables, headers),
    ...options
  }
    )};

export const GetReferralLeaderboardCurrentUserDocument = `
    query GetReferralLeaderboardCurrentUser {
  getReferralLeaderboardCurrentUser {
    avatarUrl
    creditsAmount
    firstName
    legacyUserId
    position
    referralCode
    referredUserIds
    username
    walletAddress
  }
}
    `;

export const useGetReferralLeaderboardCurrentUserQuery = <
      TData = GetReferralLeaderboardCurrentUserQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetReferralLeaderboardCurrentUserQueryVariables,
      options?: Omit<UseQueryOptions<GetReferralLeaderboardCurrentUserQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<GetReferralLeaderboardCurrentUserQuery, TError, TData>['queryKey'] },
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetReferralLeaderboardCurrentUserQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['GetReferralLeaderboardCurrentUser'] : ['GetReferralLeaderboardCurrentUser', variables],
    queryFn: fetcher<GetReferralLeaderboardCurrentUserQuery, GetReferralLeaderboardCurrentUserQueryVariables>(client, GetReferralLeaderboardCurrentUserDocument, variables, headers),
    ...options
  }
    )};

export const CreateReferralCodeDocument = `
    mutation CreateReferralCode {
  createReferralCode
}
    `;

export const useCreateReferralCodeMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<CreateReferralCodeMutation, TError, CreateReferralCodeMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<CreateReferralCodeMutation, TError, CreateReferralCodeMutationVariables, TContext>(
      {
    mutationKey: ['CreateReferralCode'],
    mutationFn: (variables?: CreateReferralCodeMutationVariables) => fetcher<CreateReferralCodeMutation, CreateReferralCodeMutationVariables>(client, CreateReferralCodeDocument, variables, headers)(),
    ...options
  }
    )};

export const GetTransactionHistoryDocument = `
    query GetTransactionHistory {
  viewer {
    id
    transactionHistory {
      id
      type
      description
      value
      txLink
      txAddress
      createdAt
      badges {
        id
        name
      }
    }
  }
}
    `;

export const useGetTransactionHistoryQuery = <
      TData = GetTransactionHistoryQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetTransactionHistoryQueryVariables,
      options?: Omit<UseQueryOptions<GetTransactionHistoryQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<GetTransactionHistoryQuery, TError, TData>['queryKey'] },
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetTransactionHistoryQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['GetTransactionHistory'] : ['GetTransactionHistory', variables],
    queryFn: fetcher<GetTransactionHistoryQuery, GetTransactionHistoryQueryVariables>(client, GetTransactionHistoryDocument, variables, headers),
    ...options
  }
    )};

export const LevelUpDocument = `
    mutation LevelUp {
  mintLevelUp
}
    `;

export const useLevelUpMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<LevelUpMutation, TError, LevelUpMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<LevelUpMutation, TError, LevelUpMutationVariables, TContext>(
      {
    mutationKey: ['LevelUp'],
    mutationFn: (variables?: LevelUpMutationVariables) => fetcher<LevelUpMutation, LevelUpMutationVariables>(client, LevelUpDocument, variables, headers)(),
    ...options
  }
    )};

export const GetBadgesDocument = `
    query GetBadges {
  viewer {
    id
    badges {
      id
      name
      imageUrl
      createdAt
    }
  }
}
    `;

export const useGetBadgesQuery = <
      TData = GetBadgesQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetBadgesQueryVariables,
      options?: Omit<UseQueryOptions<GetBadgesQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<GetBadgesQuery, TError, TData>['queryKey'] },
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetBadgesQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['GetBadges'] : ['GetBadges', variables],
    queryFn: fetcher<GetBadgesQuery, GetBadgesQueryVariables>(client, GetBadgesDocument, variables, headers),
    ...options
  }
    )};

export const UpdateRewardRedeemedDocument = `
    mutation UpdateRewardRedeemed($input: InputUpdateRewardRedeemed!) {
  updateRewardRedeemed(input: $input)
}
    `;

export const useUpdateRewardRedeemedMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UpdateRewardRedeemedMutation, TError, UpdateRewardRedeemedMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<UpdateRewardRedeemedMutation, TError, UpdateRewardRedeemedMutationVariables, TContext>(
      {
    mutationKey: ['UpdateRewardRedeemed'],
    mutationFn: (variables?: UpdateRewardRedeemedMutationVariables) => fetcher<UpdateRewardRedeemedMutation, UpdateRewardRedeemedMutationVariables>(client, UpdateRewardRedeemedDocument, variables, headers)(),
    ...options
  }
    )};

export const GetNextCategorySuggestedDocument = `
    query GetNextCategorySuggested {
  viewer {
    currentLevelInfo {
      nextCategorySuggested {
        id
        imageUrl
        name
        description
      }
    }
  }
}
    `;

export const useGetNextCategorySuggestedQuery = <
      TData = GetNextCategorySuggestedQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetNextCategorySuggestedQueryVariables,
      options?: Omit<UseQueryOptions<GetNextCategorySuggestedQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<GetNextCategorySuggestedQuery, TError, TData>['queryKey'] },
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetNextCategorySuggestedQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['GetNextCategorySuggested'] : ['GetNextCategorySuggested', variables],
    queryFn: fetcher<GetNextCategorySuggestedQuery, GetNextCategorySuggestedQueryVariables>(client, GetNextCategorySuggestedDocument, variables, headers),
    ...options
  }
    )};

export const GetLeaderboardDocument = `
    query GetLeaderboard($limit: Int, $offset: Int) {
  topUsersCount
  topUsers(limit: $limit, offset: $offset) {
    id
    avatarUrl
    username
    firstName
    walletAddress
    xp
    position
  }
}
    `;

export const useGetLeaderboardQuery = <
      TData = GetLeaderboardQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetLeaderboardQueryVariables,
      options?: Omit<UseQueryOptions<GetLeaderboardQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<GetLeaderboardQuery, TError, TData>['queryKey'] },
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetLeaderboardQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['GetLeaderboard'] : ['GetLeaderboard', variables],
    queryFn: fetcher<GetLeaderboardQuery, GetLeaderboardQueryVariables>(client, GetLeaderboardDocument, variables, headers),
    ...options
  }
    )};

export const GetUserLeaderboardDocument = `
    query GetUserLeaderboard {
  viewer {
    id
    avatarUrl
    username
    firstName
    walletAddress
    xp
  }
  viewerTopUser {
    position
  }
}
    `;

export const useGetUserLeaderboardQuery = <
      TData = GetUserLeaderboardQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetUserLeaderboardQueryVariables,
      options?: Omit<UseQueryOptions<GetUserLeaderboardQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<GetUserLeaderboardQuery, TError, TData>['queryKey'] },
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetUserLeaderboardQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['GetUserLeaderboard'] : ['GetUserLeaderboard', variables],
    queryFn: fetcher<GetUserLeaderboardQuery, GetUserLeaderboardQueryVariables>(client, GetUserLeaderboardDocument, variables, headers),
    ...options
  }
    )};

export const GetLootDropsDocument = `
    query GetLootDrops {
  getLootDrops {
    id
    coverImage
    description
    endTime
    multiplierPerCategory
    multiplierPerLevel
    name
    participantCount
    totalMultiplier
    startTime
    rewardPoolInfo
    completionPercentage
    urlSlug
    rewards {
      title
      units
    }
  }
}
    `;

export const useGetLootDropsQuery = <
      TData = GetLootDropsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetLootDropsQueryVariables,
      options?: Omit<UseQueryOptions<GetLootDropsQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<GetLootDropsQuery, TError, TData>['queryKey'] },
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetLootDropsQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['GetLootDrops'] : ['GetLootDrops', variables],
    queryFn: fetcher<GetLootDropsQuery, GetLootDropsQueryVariables>(client, GetLootDropsDocument, variables, headers),
    ...options
  }
    )};

export const GetLootDropDocument = `
    query GetLootDrop($urlSlug: String) {
  getLootDrop(urlSlug: $urlSlug, userCompletion: true) {
    id
    categoriesIds
    createdAt
    updatedAt
    categories {
      id
      name
      description
      startTime
      endTime
      imageUrl
      isActive
      campaigns
      isFeatured
      urlSlug
      completionPercentage
    }
    name
    completionPercentage
    description
    coverImage
    startTime
    endTime
    multiplier
    multiplierPerCategory
    multiplierPerLevel
    totalMultiplier
    participantCount
    urlSlug
    rewardPoolInfo
    rewards {
      id
      title
      description
      imageUrl
      active
      units
      createdAt
      rewardIntegrationId
      updatedAt
      rewardIntegration {
        constraints {
          maxUnitDistributionLimit
        }
      }
    }
  }
}
    `;

export const useGetLootDropQuery = <
      TData = GetLootDropQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetLootDropQueryVariables,
      options?: Omit<UseQueryOptions<GetLootDropQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<GetLootDropQuery, TError, TData>['queryKey'] },
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetLootDropQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['GetLootDrop'] : ['GetLootDrop', variables],
    queryFn: fetcher<GetLootDropQuery, GetLootDropQueryVariables>(client, GetLootDropDocument, variables, headers),
    ...options
  }
    )};

export const GetUserConsentLootDropDocument = `
    query GetUserConsentLootDrop($lootDropId: ID!) {
  userConsentLootDrop(lootDropId: $lootDropId)
}
    `;

export const useGetUserConsentLootDropQuery = <
      TData = GetUserConsentLootDropQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetUserConsentLootDropQueryVariables,
      options?: Omit<UseQueryOptions<GetUserConsentLootDropQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<GetUserConsentLootDropQuery, TError, TData>['queryKey'] },
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetUserConsentLootDropQuery, TError, TData>(
      {
    queryKey: ['GetUserConsentLootDrop', variables],
    queryFn: fetcher<GetUserConsentLootDropQuery, GetUserConsentLootDropQueryVariables>(client, GetUserConsentLootDropDocument, variables, headers),
    ...options
  }
    )};

export const ConsentLootDropTermsDocument = `
    mutation ConsentLootDropTerms($lootDropId: ID!) {
  consentLootDropTerms(lootDropId: $lootDropId)
}
    `;

export const useConsentLootDropTermsMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<ConsentLootDropTermsMutation, TError, ConsentLootDropTermsMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<ConsentLootDropTermsMutation, TError, ConsentLootDropTermsMutationVariables, TContext>(
      {
    mutationKey: ['ConsentLootDropTerms'],
    mutationFn: (variables?: ConsentLootDropTermsMutationVariables) => fetcher<ConsentLootDropTermsMutation, ConsentLootDropTermsMutationVariables>(client, ConsentLootDropTermsDocument, variables, headers)(),
    ...options
  }
    )};

export const AddUserReferralDocument = `
    mutation AddUserReferral($userId: String!, $referralCode: String!) {
  addUserReferral(userId: $userId, referralCode: $referralCode)
}
    `;

export const useAddUserReferralMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<AddUserReferralMutation, TError, AddUserReferralMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<AddUserReferralMutation, TError, AddUserReferralMutationVariables, TContext>(
      {
    mutationKey: ['AddUserReferral'],
    mutationFn: (variables?: AddUserReferralMutationVariables) => fetcher<AddUserReferralMutation, AddUserReferralMutationVariables>(client, AddUserReferralDocument, variables, headers)(),
    ...options
  }
    )};

export const SaveAvatarDocument = `
    mutation SaveAvatar($tokenId: Int!, $skinId: String!) {
  saveAvatar(tokenId: $tokenId, skinId: $skinId) {
    id
    avatarUrl
    isAvatarMinted
    isOnboardingCompleted
    skinId
    tokenId
  }
}
    `;

export const useSaveAvatarMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<SaveAvatarMutation, TError, SaveAvatarMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<SaveAvatarMutation, TError, SaveAvatarMutationVariables, TContext>(
      {
    mutationKey: ['SaveAvatar'],
    mutationFn: (variables?: SaveAvatarMutationVariables) => fetcher<SaveAvatarMutation, SaveAvatarMutationVariables>(client, SaveAvatarDocument, variables, headers)(),
    ...options
  }
    )};

export const MintAvatarDocument = `
    mutation MintAvatar($baseSkinId: Int!) {
  mintAvatar(baseSkinId: $baseSkinId)
}
    `;

export const useMintAvatarMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<MintAvatarMutation, TError, MintAvatarMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<MintAvatarMutation, TError, MintAvatarMutationVariables, TContext>(
      {
    mutationKey: ['MintAvatar'],
    mutationFn: (variables?: MintAvatarMutationVariables) => fetcher<MintAvatarMutation, MintAvatarMutationVariables>(client, MintAvatarDocument, variables, headers)(),
    ...options
  }
    )};

export const CompleteUserOnboardingDocument = `
    mutation CompleteUserOnboarding {
  completeUserOnboarding
}
    `;

export const useCompleteUserOnboardingMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<CompleteUserOnboardingMutation, TError, CompleteUserOnboardingMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<CompleteUserOnboardingMutation, TError, CompleteUserOnboardingMutationVariables, TContext>(
      {
    mutationKey: ['CompleteUserOnboarding'],
    mutationFn: (variables?: CompleteUserOnboardingMutationVariables) => fetcher<CompleteUserOnboardingMutation, CompleteUserOnboardingMutationVariables>(client, CompleteUserOnboardingDocument, variables, headers)(),
    ...options
  }
    )};

export const GetProposalDocument = `
    query GetProposal($proposalId: ID) {
  proposal(id: $proposalId) {
    content
    commentCount
    createdAt
    id
    plainContent
    title
    url
    status
    comments {
      ...CommentFields
    }
  }
}
    ${CommentFieldsFragmentDoc}`;

export const useGetProposalQuery = <
      TData = GetProposalQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetProposalQueryVariables,
      options?: Omit<UseQueryOptions<GetProposalQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<GetProposalQuery, TError, TData>['queryKey'] },
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetProposalQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['GetProposal'] : ['GetProposal', variables],
    queryFn: fetcher<GetProposalQuery, GetProposalQueryVariables>(client, GetProposalDocument, variables, headers),
    ...options
  }
    )};

export const GetProposalsDocument = `
    query GetProposals {
  proposals {
    content
    createdAt
    id
    plainContent
    commentCount
    url
    title
    status
  }
}
    `;

export const useGetProposalsQuery = <
      TData = GetProposalsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetProposalsQueryVariables,
      options?: Omit<UseQueryOptions<GetProposalsQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<GetProposalsQuery, TError, TData>['queryKey'] },
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetProposalsQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['GetProposals'] : ['GetProposals', variables],
    queryFn: fetcher<GetProposalsQuery, GetProposalsQueryVariables>(client, GetProposalsDocument, variables, headers),
    ...options
  }
    )};

export const CreateCharmverseMemberDocument = `
    mutation createCharmverseMember {
  createCharmverseMember {
    user {
      id
      charmverseUserId
    }
  }
}
    `;

export const useCreateCharmverseMemberMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<CreateCharmverseMemberMutation, TError, CreateCharmverseMemberMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<CreateCharmverseMemberMutation, TError, CreateCharmverseMemberMutationVariables, TContext>(
      {
    mutationKey: ['createCharmverseMember'],
    mutationFn: (variables?: CreateCharmverseMemberMutationVariables) => fetcher<CreateCharmverseMemberMutation, CreateCharmverseMemberMutationVariables>(client, CreateCharmverseMemberDocument, variables, headers)(),
    ...options
  }
    )};

export const CreateCharmverseCommentDocument = `
    mutation createCharmverseComment($input: CreateCharmverseCommentInput!) {
  createCharmverseComment(input: $input) {
    comment {
      id
    }
  }
}
    `;

export const useCreateCharmverseCommentMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<CreateCharmverseCommentMutation, TError, CreateCharmverseCommentMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<CreateCharmverseCommentMutation, TError, CreateCharmverseCommentMutationVariables, TContext>(
      {
    mutationKey: ['createCharmverseComment'],
    mutationFn: (variables?: CreateCharmverseCommentMutationVariables) => fetcher<CreateCharmverseCommentMutation, CreateCharmverseCommentMutationVariables>(client, CreateCharmverseCommentDocument, variables, headers)(),
    ...options
  }
    )};

export const VoteOnCharmverseCommentDocument = `
    mutation voteOnCharmverseComment($input: VoteOnCharmverseCommentInput!) {
  voteOnCharmverseComment(input: $input) {
    comment {
      id
      upvotes
      downvotes
    }
  }
}
    `;

export const useVoteOnCharmverseCommentMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<VoteOnCharmverseCommentMutation, TError, VoteOnCharmverseCommentMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<VoteOnCharmverseCommentMutation, TError, VoteOnCharmverseCommentMutationVariables, TContext>(
      {
    mutationKey: ['voteOnCharmverseComment'],
    mutationFn: (variables?: VoteOnCharmverseCommentMutationVariables) => fetcher<VoteOnCharmverseCommentMutation, VoteOnCharmverseCommentMutationVariables>(client, VoteOnCharmverseCommentDocument, variables, headers)(),
    ...options
  }
    )};

export const DeleteCharmverseCommentDocument = `
    mutation deleteCharmverseComment($input: DeleteCharmverseCommentInput!) {
  deleteCharmverseComment(input: $input) {
    comment {
      id
    }
  }
}
    `;

export const useDeleteCharmverseCommentMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<DeleteCharmverseCommentMutation, TError, DeleteCharmverseCommentMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<DeleteCharmverseCommentMutation, TError, DeleteCharmverseCommentMutationVariables, TContext>(
      {
    mutationKey: ['deleteCharmverseComment'],
    mutationFn: (variables?: DeleteCharmverseCommentMutationVariables) => fetcher<DeleteCharmverseCommentMutation, DeleteCharmverseCommentMutationVariables>(client, DeleteCharmverseCommentDocument, variables, headers)(),
    ...options
  }
    )};

export const EditCharmverseCommentDocument = `
    mutation editCharmverseComment($input: EditCharmverseCommentInput!) {
  editCharmverseComment(input: $input) {
    comment {
      ...CommentFields
    }
  }
}
    ${CommentFieldsFragmentDoc}`;

export const useEditCharmverseCommentMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<EditCharmverseCommentMutation, TError, EditCharmverseCommentMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<EditCharmverseCommentMutation, TError, EditCharmverseCommentMutationVariables, TContext>(
      {
    mutationKey: ['editCharmverseComment'],
    mutationFn: (variables?: EditCharmverseCommentMutationVariables) => fetcher<EditCharmverseCommentMutation, EditCharmverseCommentMutationVariables>(client, EditCharmverseCommentDocument, variables, headers)(),
    ...options
  }
    )};

export const GetCategoriesDocument = `
    query GetCategories($isActive: Boolean, $userCompletion: Boolean, $showLootDropCategories: Boolean) {
  categories(
    isActive: $isActive
    userCompletion: $userCompletion
    showLootDropCategories: $showLootDropCategories
  ) {
    id
    urlSlug
    name
    description
    backgroundUrl
    imageUrl
    isFeatured
    isActive
    campaigns
    completionPercentage
    totalCredits
    totalXP
  }
}
    `;

export const useGetCategoriesQuery = <
      TData = GetCategoriesQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetCategoriesQueryVariables,
      options?: Omit<UseQueryOptions<GetCategoriesQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<GetCategoriesQuery, TError, TData>['queryKey'] },
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetCategoriesQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['GetCategories'] : ['GetCategories', variables],
    queryFn: fetcher<GetCategoriesQuery, GetCategoriesQueryVariables>(client, GetCategoriesDocument, variables, headers),
    ...options
  }
    )};

export const GetCampaignsDocument = `
    query GetCampaigns {
  campaigns {
    key
    name
  }
}
    `;

export const useGetCampaignsQuery = <
      TData = GetCampaignsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetCampaignsQueryVariables,
      options?: Omit<UseQueryOptions<GetCampaignsQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<GetCampaignsQuery, TError, TData>['queryKey'] },
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetCampaignsQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['GetCampaigns'] : ['GetCampaigns', variables],
    queryFn: fetcher<GetCampaignsQuery, GetCampaignsQueryVariables>(client, GetCampaignsDocument, variables, headers),
    ...options
  }
    )};

export const GetQuestDocument = `
    query GetQuest($questId: ID!) {
  quest(id: $questId) {
    description
    earnAchievement {
      id
      imageUrl
      name
      xp
    }
    avatarUrl
    name
    isOrdered
    instructions
    id
    entryXP
    entryRank
    earnXP
    earnCredits
    earnRank
    isCompleted
    tasks {
      id
      featureId
      name
      description
      instructions
      avatarUrl
      earnXP
      earnCredits
      actionType
      isRequired
      isCompleted
      showManualCheckButton
      status
      inputData
      integrationFeature {
        id
        integrationKey
      }
    }
  }
}
    `;

export const useGetQuestQuery = <
      TData = GetQuestQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetQuestQueryVariables,
      options?: Omit<UseQueryOptions<GetQuestQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<GetQuestQuery, TError, TData>['queryKey'] },
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetQuestQuery, TError, TData>(
      {
    queryKey: ['GetQuest', variables],
    queryFn: fetcher<GetQuestQuery, GetQuestQueryVariables>(client, GetQuestDocument, variables, headers),
    ...options
  }
    )};

export const CheckTaskCompletion2Document = `
    mutation CheckTaskCompletion2($questId: ID!, $taskId: ID!) {
  checkTaskCompletion2(questId: $questId, taskId: $taskId)
}
    `;

export const useCheckTaskCompletion2Mutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<CheckTaskCompletion2Mutation, TError, CheckTaskCompletion2MutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<CheckTaskCompletion2Mutation, TError, CheckTaskCompletion2MutationVariables, TContext>(
      {
    mutationKey: ['CheckTaskCompletion2'],
    mutationFn: (variables?: CheckTaskCompletion2MutationVariables) => fetcher<CheckTaskCompletion2Mutation, CheckTaskCompletion2MutationVariables>(client, CheckTaskCompletion2Document, variables, headers)(),
    ...options
  }
    )};

export const UpdatePartnerUserDocument = `
    mutation UpdatePartnerUser($partnerUserId: ID!, $featureId: String) {
  updatePartnerUser(partnerUserId: $partnerUserId, featureId: $featureId)
}
    `;

export const useUpdatePartnerUserMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UpdatePartnerUserMutation, TError, UpdatePartnerUserMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<UpdatePartnerUserMutation, TError, UpdatePartnerUserMutationVariables, TContext>(
      {
    mutationKey: ['UpdatePartnerUser'],
    mutationFn: (variables?: UpdatePartnerUserMutationVariables) => fetcher<UpdatePartnerUserMutation, UpdatePartnerUserMutationVariables>(client, UpdatePartnerUserDocument, variables, headers)(),
    ...options
  }
    )};

export const CheckTaskCompletionDocument = `
    mutation CheckTaskCompletion($questId: ID!, $taskId: ID!) {
  checkTaskCompletion(questId: $questId, taskId: $taskId)
}
    `;

export const useCheckTaskCompletionMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<CheckTaskCompletionMutation, TError, CheckTaskCompletionMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<CheckTaskCompletionMutation, TError, CheckTaskCompletionMutationVariables, TContext>(
      {
    mutationKey: ['CheckTaskCompletion'],
    mutationFn: (variables?: CheckTaskCompletionMutationVariables) => fetcher<CheckTaskCompletionMutation, CheckTaskCompletionMutationVariables>(client, CheckTaskCompletionDocument, variables, headers)(),
    ...options
  }
    )};

export const DisconnectSocialNetworkDocument = `
    mutation disconnectSocialNetwork($socialAccounts: SocialAccounts!) {
  disconnectFromSocialNetwork(socialAccounts: $socialAccounts) {
    id
    charmverseUserId
    discordUserId
    discordUsername
    twitterUsername
  }
}
    `;

export const useDisconnectSocialNetworkMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<DisconnectSocialNetworkMutation, TError, DisconnectSocialNetworkMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<DisconnectSocialNetworkMutation, TError, DisconnectSocialNetworkMutationVariables, TContext>(
      {
    mutationKey: ['disconnectSocialNetwork'],
    mutationFn: (variables?: DisconnectSocialNetworkMutationVariables) => fetcher<DisconnectSocialNetworkMutation, DisconnectSocialNetworkMutationVariables>(client, DisconnectSocialNetworkDocument, variables, headers)(),
    ...options
  }
    )};

export const FinishTutorialDocument = `
    mutation FinishTutorial {
  completeTutorial
}
    `;

export const useFinishTutorialMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<FinishTutorialMutation, TError, FinishTutorialMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<FinishTutorialMutation, TError, FinishTutorialMutationVariables, TContext>(
      {
    mutationKey: ['FinishTutorial'],
    mutationFn: (variables?: FinishTutorialMutationVariables) => fetcher<FinishTutorialMutation, FinishTutorialMutationVariables>(client, FinishTutorialDocument, variables, headers)(),
    ...options
  }
    )};

export const UpdateCurrentUserImageDocument = `
    mutation UpdateCurrentUserImage($avatarUrl: String) {
  updateCurrentUserSettings(avatarUrl: $avatarUrl) {
    id
    avatarUrl
  }
}
    `;

export const useUpdateCurrentUserImageMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UpdateCurrentUserImageMutation, TError, UpdateCurrentUserImageMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<UpdateCurrentUserImageMutation, TError, UpdateCurrentUserImageMutationVariables, TContext>(
      {
    mutationKey: ['UpdateCurrentUserImage'],
    mutationFn: (variables?: UpdateCurrentUserImageMutationVariables) => fetcher<UpdateCurrentUserImageMutation, UpdateCurrentUserImageMutationVariables>(client, UpdateCurrentUserImageDocument, variables, headers)(),
    ...options
  }
    )};

export const GetCategoryBySlugDocument = `
    query GetCategoryBySlug($urlSlug: String!, $userCompletion: Boolean) {
  category(urlSlug: $urlSlug, userCompletion: $userCompletion) {
    id
    name
    description
    urlSlug
    imageUrl
    backgroundUrl
    startTime
    endTime
    campaigns
    completionPercentage
    rewards {
      id
      units
      title
      description
    }
  }
}
    `;

export const useGetCategoryBySlugQuery = <
      TData = GetCategoryBySlugQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetCategoryBySlugQueryVariables,
      options?: Omit<UseQueryOptions<GetCategoryBySlugQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<GetCategoryBySlugQuery, TError, TData>['queryKey'] },
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetCategoryBySlugQuery, TError, TData>(
      {
    queryKey: ['GetCategoryBySlug', variables],
    queryFn: fetcher<GetCategoryBySlugQuery, GetCategoryBySlugQueryVariables>(client, GetCategoryBySlugDocument, variables, headers),
    ...options
  }
    )};

export const GetCategoryQuestsDocument = `
    query GetCategoryQuests($categoryId: ID, $categoryName: String) {
  quests(categoryId: $categoryId, categoryName: $categoryName) {
    id
    name
    description
    avatarUrl
    isCompleted
    status
    instructions
    entryRank
    entryXP
    earnXP
    earnCredits
    earnRank
    tasks {
      id
      featureId
      name
      description
      instructions
      avatarUrl
      earnXP
      earnCredits
      actionType
      isRequired
      isCompleted
      inputData
      showManualCheckButton
      status
      inputData
      integrationFeature {
        id
        integrationKey
      }
    }
    rewards {
      title
      description
      units
    }
  }
}
    `;

export const useGetCategoryQuestsQuery = <
      TData = GetCategoryQuestsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetCategoryQuestsQueryVariables,
      options?: Omit<UseQueryOptions<GetCategoryQuestsQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<GetCategoryQuestsQuery, TError, TData>['queryKey'] },
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetCategoryQuestsQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['GetCategoryQuests'] : ['GetCategoryQuests', variables],
    queryFn: fetcher<GetCategoryQuestsQuery, GetCategoryQuestsQueryVariables>(client, GetCategoryQuestsDocument, variables, headers),
    ...options
  }
    )};

export const GetRewardsRedeemedDocument = `
    query GetRewardsRedeemed($input: InputRewardRedeemed!) {
  rewardsRedeemed(input: $input) {
    id
    imageUrl
    title
    units
    redeemed
    lootDropId
    description
    rewardIntegration {
      additionalData
      id
    }
  }
}
    `;

export const useGetRewardsRedeemedQuery = <
      TData = GetRewardsRedeemedQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables: GetRewardsRedeemedQueryVariables,
      options?: Omit<UseQueryOptions<GetRewardsRedeemedQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<GetRewardsRedeemedQuery, TError, TData>['queryKey'] },
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetRewardsRedeemedQuery, TError, TData>(
      {
    queryKey: ['GetRewardsRedeemed', variables],
    queryFn: fetcher<GetRewardsRedeemedQuery, GetRewardsRedeemedQueryVariables>(client, GetRewardsRedeemedDocument, variables, headers),
    ...options
  }
    )};

export const RedeemRewardDocument = `
    mutation RedeemReward($input: InputRedeemReward!) {
  redeemReward(input: $input)
}
    `;

export const useRedeemRewardMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<RedeemRewardMutation, TError, RedeemRewardMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<RedeemRewardMutation, TError, RedeemRewardMutationVariables, TContext>(
      {
    mutationKey: ['RedeemReward'],
    mutationFn: (variables?: RedeemRewardMutationVariables) => fetcher<RedeemRewardMutation, RedeemRewardMutationVariables>(client, RedeemRewardDocument, variables, headers)(),
    ...options
  }
    )};

export const AddOnChainTransactionHistoryDocument = `
    mutation AddOnChainTransactionHistory($txAddress: String!, $contractAddress: String!, $chainId: Int!, $description: String!, $txLink: String!) {
  addOnChainTransactionHistory(
    txAddress: $txAddress
    contractAddress: $contractAddress
    chainId: $chainId
    description: $description
    txLink: $txLink
  )
}
    `;

export const useAddOnChainTransactionHistoryMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<AddOnChainTransactionHistoryMutation, TError, AddOnChainTransactionHistoryMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<AddOnChainTransactionHistoryMutation, TError, AddOnChainTransactionHistoryMutationVariables, TContext>(
      {
    mutationKey: ['AddOnChainTransactionHistory'],
    mutationFn: (variables?: AddOnChainTransactionHistoryMutationVariables) => fetcher<AddOnChainTransactionHistoryMutation, AddOnChainTransactionHistoryMutationVariables>(client, AddOnChainTransactionHistoryDocument, variables, headers)(),
    ...options
  }
    )};

export const GetUserDocument = `
    query GetUser {
  viewer {
    id
    tokenId
    walletAddress
    discordUsername
    emailAddress
    avatarUrl
    firstName
    lastName
    username
    githubUsername
    twitterUsername
    charmverseUserId
    hasCompletedTutorial
    isOnboardingCompleted
    isLootDropOnboardingCompleted
    isAvatarMinted
    referralCode
    referrerCode
    skinId
    avatarMintUrl
    avatarMintTx
    level
    currentLevelInfo {
      completedRequiredQuests
      totalLevelRequiredQuests
      totalLevelRequiredXp
      userLevelXp
      minXpToNextLevel
    }
    xp
    rank
    customData {
      solanaWallet {
        address
      }
      telegram {
        id
      }
      linea {
        guild
      }
    }
    isCreditsOnboardingCompleted
  }
}
    `;

export const useGetUserQuery = <
      TData = GetUserQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetUserQueryVariables,
      options?: Omit<UseQueryOptions<GetUserQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<GetUserQuery, TError, TData>['queryKey'] },
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetUserQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['GetUser'] : ['GetUser', variables],
    queryFn: fetcher<GetUserQuery, GetUserQueryVariables>(client, GetUserDocument, variables, headers),
    ...options
  }
    )};

export const UpdateCurrentUserSettingsDocument = `
    mutation UpdateCurrentUserSettings($firstName: String, $lastName: String, $discordUsername: String, $emailAddress: String, $username: String, $solanaWalletAddress: String, $isLootDropOnboardingCompleted: Boolean, $isCreditsOnboardingCompleted: Boolean, $telegramId: String, $lineaGuild: String) {
  updateCurrentUserSettings(
    firstName: $firstName
    lastName: $lastName
    discordUsername: $discordUsername
    emailAddress: $emailAddress
    username: $username
    solanaWalletAddress: $solanaWalletAddress
    isLootDropOnboardingCompleted: $isLootDropOnboardingCompleted
    isCreditsOnboardingCompleted: $isCreditsOnboardingCompleted
    telegramId: $telegramId
    lineaGuild: $lineaGuild
  ) {
    id
    firstName
    lastName
    username
    emailAddress
    discordUsername
    isOnboardingCompleted
    isLootDropOnboardingCompleted
    isAvatarMinted
    customData {
      solanaWallet {
        address
      }
      telegram {
        id
      }
      linea {
        guild
      }
    }
    isCreditsOnboardingCompleted
  }
}
    `;

export const useUpdateCurrentUserSettingsMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<UpdateCurrentUserSettingsMutation, TError, UpdateCurrentUserSettingsMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<UpdateCurrentUserSettingsMutation, TError, UpdateCurrentUserSettingsMutationVariables, TContext>(
      {
    mutationKey: ['UpdateCurrentUserSettings'],
    mutationFn: (variables?: UpdateCurrentUserSettingsMutationVariables) => fetcher<UpdateCurrentUserSettingsMutation, UpdateCurrentUserSettingsMutationVariables>(client, UpdateCurrentUserSettingsDocument, variables, headers)(),
    ...options
  }
    )};

export const GetAllDnaTraitsDocument = `
    query GetAllDnaTraits {
  viewer {
    inventoryItems(isDna: true) {
      id
      imageUrl
      glbUrl
      slotType
      isEquipped
      soulbound
      uri
      chain {
        tokenId
        address
      }
    }
  }
}
    `;

export const useGetAllDnaTraitsQuery = <
      TData = GetAllDnaTraitsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetAllDnaTraitsQueryVariables,
      options?: Omit<UseQueryOptions<GetAllDnaTraitsQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<GetAllDnaTraitsQuery, TError, TData>['queryKey'] },
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetAllDnaTraitsQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['GetAllDnaTraits'] : ['GetAllDnaTraits', variables],
    queryFn: fetcher<GetAllDnaTraitsQuery, GetAllDnaTraitsQueryVariables>(client, GetAllDnaTraitsDocument, variables, headers),
    ...options
  }
    )};

export const GetEquippedDnaItemsDocument = `
    query GetEquippedDnaItems {
  viewer {
    inventoryItems(equippedOnly: true, isDna: true) {
      id
      imageUrl
      glbUrl
      slotType
      isEquipped
      soulbound
      uri
      chain {
        tokenId
        address
      }
    }
  }
}
    `;

export const useGetEquippedDnaItemsQuery = <
      TData = GetEquippedDnaItemsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetEquippedDnaItemsQueryVariables,
      options?: Omit<UseQueryOptions<GetEquippedDnaItemsQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<GetEquippedDnaItemsQuery, TError, TData>['queryKey'] },
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetEquippedDnaItemsQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['GetEquippedDnaItems'] : ['GetEquippedDnaItems', variables],
    queryFn: fetcher<GetEquippedDnaItemsQuery, GetEquippedDnaItemsQueryVariables>(client, GetEquippedDnaItemsDocument, variables, headers),
    ...options
  }
    )};

export const GetInventoryItemsByIdsDocument = `
    query GetInventoryItemsByIds($tokenIds: [ID!]) {
  viewer {
    inventoryItems(tokenIds: $tokenIds) {
      id
      slotType
      isEquipped
      soulbound
      uri
      imageUrl
      glbUrl
      chain {
        tokenId
        address
      }
    }
  }
}
    `;

export const useGetInventoryItemsByIdsQuery = <
      TData = GetInventoryItemsByIdsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetInventoryItemsByIdsQueryVariables,
      options?: Omit<UseQueryOptions<GetInventoryItemsByIdsQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<GetInventoryItemsByIdsQuery, TError, TData>['queryKey'] },
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetInventoryItemsByIdsQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['GetInventoryItemsByIds'] : ['GetInventoryItemsByIds', variables],
    queryFn: fetcher<GetInventoryItemsByIdsQuery, GetInventoryItemsByIdsQueryVariables>(client, GetInventoryItemsByIdsDocument, variables, headers),
    ...options
  }
    )};

export const GetInventoryItemsDocument = `
    query GetInventoryItems {
  viewer {
    id
    skinId
    inventoryItems {
      id
      imageUrl
      glbUrl
      isEquipped
      slotType
      count
      soulbound
      chain {
        tokenId
        address
      }
    }
  }
}
    `;

export const useGetInventoryItemsQuery = <
      TData = GetInventoryItemsQuery,
      TError = unknown
    >(
      client: GraphQLClient,
      variables?: GetInventoryItemsQueryVariables,
      options?: Omit<UseQueryOptions<GetInventoryItemsQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<GetInventoryItemsQuery, TError, TData>['queryKey'] },
      headers?: RequestInit['headers']
    ) => {
    
    return useQuery<GetInventoryItemsQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['GetInventoryItems'] : ['GetInventoryItems', variables],
    queryFn: fetcher<GetInventoryItemsQuery, GetInventoryItemsQueryVariables>(client, GetInventoryItemsDocument, variables, headers),
    ...options
  }
    )};

export const EquipInventoryItemsDocument = `
    mutation equipInventoryItems($payload: InventoryInput!) {
  equipInventoryItems(payload: $payload)
}
    `;

export const useEquipInventoryItemsMutation = <
      TError = unknown,
      TContext = unknown
    >(
      client: GraphQLClient,
      options?: UseMutationOptions<EquipInventoryItemsMutation, TError, EquipInventoryItemsMutationVariables, TContext>,
      headers?: RequestInit['headers']
    ) => {
    
    return useMutation<EquipInventoryItemsMutation, TError, EquipInventoryItemsMutationVariables, TContext>(
      {
    mutationKey: ['equipInventoryItems'],
    mutationFn: (variables?: EquipInventoryItemsMutationVariables) => fetcher<EquipInventoryItemsMutation, EquipInventoryItemsMutationVariables>(client, EquipInventoryItemsDocument, variables, headers)(),
    ...options
  }
    )};
