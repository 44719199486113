import { useState } from 'react'
import { TenantImageEnum, useAssetsContext } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { Button, Stack, Title, Modal, Text, Image, Paper, Group, Center } from 'summon-ui/mantine'
import type { Maybe } from '@generated/generates'
import { useRedeem } from '@hooks'

const MODAL_TITLE = 'Claim'
const MODAL_DESCRIPTION = 'Clicking ‘Claim’ will redeem the reward. Proceed?'

interface Props {
  redeemableId: string
  isRedeemed: boolean
  units: number
  title: string
  imageUrl: Maybe<string> | undefined
  isSponsored: boolean
}

const Redeemable = ({ redeemableId, isRedeemed, units, title, imageUrl, isSponsored }: Props) => {
  const t = useTranslation()
  const [isMintModalOpen, setIsMintModalOpen] = useState(false)
  const { getTenantImage } = useAssetsContext()
  const misteryBoxImage = getTenantImage(TenantImageEnum.MisteryBox)

  const handleCloseMintModal = () => {
    setIsMintModalOpen(false)
  }
  const { isRedeemMutationLoading, redeem, isRedeeming } = useRedeem({
    redeemableId,
    handleCloseMintModal,
    isSponsored
  })

  return (
    <>
      <Stack gap='xs'>
        <Paper withBorder radius='md' w={155} p='sm'>
          <Stack gap='xs' w='100%'>
            <Center h={100} w='100%'>
              <Image
                radius='md'
                w='auto'
                h='100%'
                fit='contain'
                alt='claimable'
                src={imageUrl || misteryBoxImage}
                fallbackSrc='https://placehold.co/600x400?text=Placeholder'
              />
            </Center>
            <Center h={50}>
              <Text ta='center' fw={500} lineClamp={2}>
                {units} {title}
              </Text>
            </Center>
          </Stack>
        </Paper>
        {isRedeemed ? (
          <Button fullWidth variant='outline' disabled>
            {t('Claimed')}
          </Button>
        ) : (
          <Button fullWidth onClick={() => setIsMintModalOpen(true)} loading={isRedeemMutationLoading}>
            {t('Claim')}
          </Button>
        )}
      </Stack>

      <Modal opened={isMintModalOpen} onClose={handleCloseMintModal} withCloseButton={false} size='xs'>
        <Stack>
          <Title order={4}>{t(MODAL_TITLE)}</Title>
          <Text>{MODAL_DESCRIPTION}</Text>
          {isSponsored ? (
            <Button w='100%' onClick={redeem} loading={isRedeemMutationLoading}>
              {t('Claim')}
            </Button>
          ) : (
            <Group gap='sm' wrap='nowrap'>
              <Button miw={100} onClick={redeem} loading={isRedeeming}>
                {t('Claim')}
              </Button>
            </Group>
          )}
        </Stack>
      </Modal>
    </>
  )
}

export default Redeemable
