import { useEffect } from 'react'
import { showNotification } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { formatUnits } from 'viem'
import { useBalance } from 'wagmi'
import { type UseMutateAsyncFunction, useQueryClient } from '@tanstack/react-query'
import { graphqlClient } from '@api/config'
import { TENANT_CONFIG, TENANT_CONTRACTS } from '@config'
import { type Exact, type LevelUpMutation, useLevelUpMutation } from '@generated/generates'
import { isStaging } from '@utils/isStaging'

const MIN_BALANCE_TO_SPONSOR = isStaging ? 0.025 : 0.0001

const useMintLevelUp = (): {
  mint: UseMutateAsyncFunction<LevelUpMutation, unknown, Exact<{ [key: string]: never }>, unknown>
  isMinting: boolean
  disabled: boolean
} => {
  const t = useTranslation()
  const { data } = useBalance({
    address: isStaging ? TENANT_CONTRACTS.testnet.achievoWallet : TENANT_CONTRACTS.mainnet.achievoWallet,
    chainId: TENANT_CONFIG.network.id
  })

  const queryClient = useQueryClient()
  const hasBalance = data ? Number(formatUnits(data.value, data.decimals)) > MIN_BALANCE_TO_SPONSOR : null

  useEffect(() => {
    if (hasBalance === false) {
      showNotification({
        variant: 'info',
        message: t('unexpected error: No balance found. Please try again later.')
      })
    }
  }, [hasBalance])

  const { mutateAsync: mint, isPending: isMinting } = useLevelUpMutation(graphqlClient(), {
    onSuccess: () => {
      showNotification({
        variant: 'success',
        message: t('Level up successful!')
      })
      queryClient.invalidateQueries({ queryKey: ['inventoryItemsWithMetadata'] })
      queryClient.invalidateQueries({ queryKey: ['GetInventoryItemsByIds'] })
      queryClient.invalidateQueries({ queryKey: ['GetInventoryItems'] })
    },
    onError: () => {
      showNotification({
        variant: 'danger',
        message: t('Could not level up')
      })
    }
  })

  return {
    mint,
    isMinting,
    disabled: !hasBalance
  }
}

export default useMintLevelUp
