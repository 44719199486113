import { Spinner } from 'summon-ui'
import { Center, Stack } from 'summon-ui/mantine'
import QuestContainer from '@features/quests/components/QuestContainer'
import type { Quest } from '@generated/generates'
import { useGetCategoryQuests } from '@hooks'

interface Props {
  categoryId: string
  lootdropId?: string
  isLocked?: boolean
}

const QuestsContainerList = ({ categoryId, lootdropId, isLocked }: Props) => {
  const { quests, isLoadingQuests } = useGetCategoryQuests({ categoryId })

  return isLoadingQuests ? (
    <Center h='100%' w='100%'>
      <Spinner />
    </Center>
  ) : (
    <Stack>
      {quests?.map((quest) => (
        <QuestContainer key={quest.id} quest={quest as Quest} lootdropId={lootdropId} isLocked={isLocked} />
      ))}
    </Stack>
  )
}
export default QuestsContainerList
