import { useState } from 'react'
import clsx from 'clsx'
import { Icon } from 'summon-ui'
import { Box, Flex, Image, Paper, type PaperProps, Skeleton, Text } from 'summon-ui/mantine'

type InventorySlotProps = {
  image?: string
  isActive?: boolean
  isLoading?: boolean
  onClick?: () => void
  count?: number
  testId?: string
} & PaperProps

const InventorySlot = ({
  image,
  isLoading = false,
  isActive = false,
  count = 1,
  className,
  testId,
  ...props
}: InventorySlotProps) => {
  const [isLoaded, setLoaded] = useState(false)
  const showCount = count > 1

  return (
    <Skeleton visible={(!isLoaded && !!image) || isLoading} w={108} h={110}>
      <Paper
        h={104}
        withBorder
        radius='sm'
        bg='transparent'
        className={clsx({ '!border-brand': isActive }, className, ' relative cursor-pointer aspect-square')}
        data-testid={testId}
        {...props}
      >
        <Flex align='center' justify='center' h='100%'>
          {isActive && (
            <Box className='absolute top-1 right-1'>
              <Icon name='CheckCircle' c='brand' />
            </Box>
          )}
          {!image ? (
            <Text fw='bold'>Empty</Text>
          ) : (
            <>
              <Image alt='inventory slot' src={image} onLoad={() => setLoaded(true)} w='auto' fit='contain' />
              {showCount && (
                <Box pos='absolute' top={0} left={6} p={2}>
                  {count}
                </Box>
              )}
            </>
          )}
        </Flex>
      </Paper>
    </Skeleton>
  )
}

export default InventorySlot
