import { useState } from 'react'
import { useGqlMutation } from '@api/gql/gqlServices'
import { UpdateCurrentUserSettingsDocument, type UpdateCurrentUserSettingsMutation } from '@generated/generates'

interface UpdateUserSettingsOptions {
  onSuccess?: () => void
  onError?: (error: unknown) => void
  onFinally?: () => void
}

// Define the type for update data based on the mutation arguments
export type UpdateUserSettingsData = {
  username?: string
  firstName?: string
  lastName?: string
  discordUsername?: string
  emailAddress?: string
  avatarUrl?: string
  solanaWalletAddress?: string
  telegramId?: string
  lineaGuild?: string
  isLootDropOnboardingCompleted?: boolean
  isCreditsOnboardingCompleted?: boolean
}

const useUpdateViewerSettings = () => {
  const [isUpdating, setIsUpdating] = useState(false)

  const { mutateAsync: updateUserSettingsMutation } = useGqlMutation<UpdateCurrentUserSettingsMutation>(
    UpdateCurrentUserSettingsDocument
  )

  const updateViewerSettings = async (data: UpdateUserSettingsData, options: UpdateUserSettingsOptions = {}) => {
    const { onSuccess, onError, onFinally } = options

    setIsUpdating(true)
    try {
      await updateUserSettingsMutation(data)
      onSuccess && onSuccess()
    } catch (error) {
      onError && onError(error)
    } finally {
      setIsUpdating(false)
      onFinally && onFinally()
    }
  }

  return {
    updateViewerSettings,
    isUpdating
  }
}

export default useUpdateViewerSettings
