import { Icon } from 'summon-ui'
import { Box, Center, Paper, type PaperProps, Text, Tooltip } from 'summon-ui/mantine'

interface TootipInfo {
  label: string
  w?: string | number
}
type InfoCardProps = {
  info: string | number
  description: string
  tooltipInfo?: TootipInfo
  size?: 'small' | 'medium' | 'large'
} & PaperProps

const InfoCard = ({ info, description, tooltipInfo, size = 'medium', ...props }: InfoCardProps) => {
  const paddingY = {
    small: '4',
    medium: 'lg',
    large: 'xl'
  }[size]
  return (
    <Paper withBorder className='!border-yellow-3 text-center' px='xs' py={paddingY} w='100%' radius='md' {...props}>
      <Text size='xl' fw='bold' c='yellow.6'>
        {info}
      </Text>
      <Center h='24px'>
        <Text size='xs' c='yellow.1' pl={tooltipInfo ? '6' : '0'}>
          {description}
        </Text>
        {tooltipInfo && (
          <Tooltip multiline withArrow label={tooltipInfo.label} w={tooltipInfo.w}>
            <Box>
              <Icon name='InfoCircle' c='yellow.1' />
            </Box>
          </Tooltip>
        )}
      </Center>
    </Paper>
  )
}

export default InfoCard
