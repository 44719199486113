import { useMemo } from 'react'
import type { InventoryItem } from '@generated/generates'
import { SlotType } from './useAvatarStore'

const DNA_SLOT_TYPES = [SlotType.Body]

const useGroupedItems = (items: InventoryItem[], previewItems: InventoryItem[], isDNA: boolean) => {
  return useMemo(() => {
    const countedItems = (isDNA ? items : previewItems)?.filter((item) =>
      isDNA ? DNA_SLOT_TYPES.includes(item.slotType as SlotType) : item.slotType !== SlotType.Body
    )

    // Count occurrences of each item ID
    const counts = countedItems.reduce((acc, item) => {
      const itemId = item.id
      //@ts-ignore
      acc[itemId] = (acc[itemId] || 0) + 1
      return acc
    }, {})

    // Group items by slot type, taking count into consideration
    const groupedItems: { [key: string]: InventoryItem[] } = {}
    countedItems.forEach((item) => {
      //@ts-ignore
      const slotType = item.slotType.toString()
      const existingItems = groupedItems[slotType] || []
      if (!existingItems.some((existingItem) => existingItem.id === item.id)) {
        //@ts-ignore
        const newItem = { ...item, count: counts[item.id] }
        groupedItems[slotType] = [...existingItems, newItem]
      }
    })

    return groupedItems
  }, [items, previewItems, isDNA])
}

export default useGroupedItems
