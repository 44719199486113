import { useGqlQuery } from '@api/gql/gqlServices'
import { GetEquippedDnaItemsDocument, type GetEquippedDnaItemsQuery } from '@generated/generates'

interface Props {
  enabled: boolean
}

const useEquippedDnaItems = ({ enabled }: Props) => {
  const { data: equippedDnaItemsData, isLoading: isEquippedDnaItemsLoading } = useGqlQuery<GetEquippedDnaItemsQuery>(
    GetEquippedDnaItemsDocument,
    {},
    {
      enabled
    }
  )

  const equippedDnaItems = equippedDnaItemsData?.viewer?.inventoryItems

  return {
    equippedDnaItems,
    isEquippedDnaItemsLoading
  }
}

export default useEquippedDnaItems
