import { useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { type IconName, useMediaScreen } from 'summon-ui'
import type { LayoutHeaderConf } from 'summon-ui/g7'
import { useTranslation } from 'summon-ui/intl'
import { TENANT_CONFIG } from '@config'
import { useFilter } from '@features/marketplace/contexts/FilterContext'
import { useCart } from '@features/marketplace/hooks/useCart'
import { MARKETPLACE_TABS } from '@features/marketplace/marketplace.config'
import { SETTINGS_TABS } from '@features/settings/settings.config'
import { TutorialIds } from '@hocs/Tutorial/helpers.tutorial'
import { useRedeemables, useViewer } from '@hooks'

const useGetLayoutHeaderConf = () => {
  const cart = useCart()
  const t = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const { isAuthenticated } = useViewer()
  const { rewardsRedeemables } = useRedeemables({ active: true })
  const { setIsOpen: setIsFilterOpen } = useFilter()
  const isMobile = useMediaScreen({ breakpoint: 'mobile' })
  const redeemablesToClaimCount = rewardsRedeemables
    ? rewardsRedeemables.filter((item) => item.redeemed === false).length
    : 0

  const pathname = location.pathname

  const config = useMemo(() => {
    let conf: LayoutHeaderConf | undefined

    switch (true) {
      case pathname.startsWith('/marketplace'): {
        const list = MARKETPLACE_TABS.map((tab) => ({
          name: tab.name,
          to: tab.to,
          action: () => navigate(tab.to)
        }))

        const actions = TENANT_CONFIG.api.shopify
          ? [
              ...(pathname.includes('collections')
                ? [
                    {
                      name: 'Filters',
                      iconName: 'FilterLines' as IconName,
                      action: () => setIsFilterOpen(true)
                    }
                  ]
                : []),
              {
                name: `${cart.totalItemsInCart} Items`,
                iconName: 'ShoppingBag01' as IconName,
                action: () => cart.setIsCartOpen(true)
              }
            ]
          : []

        conf = {
          name: t('Marketplace'),
          tabs: {
            list,
            value: pathname
          },
          actions
        }
        break
      }
      case pathname.startsWith('/settings'): {
        const list = SETTINGS_TABS.map((tab) => ({
          name: tab.name,
          to: tab.to,
          action: () => navigate(tab.to)
        }))

        conf = {
          name: t('Settings'),
          tabs: {
            list,
            value: pathname
          }
        }
        break
      }
      case pathname.startsWith('/quests'):
        conf = {
          name: t('Quests')
        }
        break
      case pathname.startsWith('/lootdrop'):
        conf = {
          name: t('LootDrop')
        }
        break
      case pathname === '/leaderboard':
        conf = {
          name: t('Leaderboard')
        }
        break
      case pathname.startsWith('/credits'):
        conf = {
          name: t('Credits')
        }
        break
      case pathname === '/':
      case pathname === '/rewards':
      case pathname === '/inventory': {
        if (isAuthenticated) {
          const isInventoryOpen = pathname === '/inventory'

          const actions = [
            {
              name: 'Rewards',
              id: TutorialIds.Rewards,
              iconName: 'Shield01' as IconName,
              action: () => navigate('/rewards'),
              indicatorCount: redeemablesToClaimCount
            },
            {
              name: 'Avatar',
              id: TutorialIds.Avatar,
              iconName: 'Loading03' as IconName,
              action: () => navigate('/inventory')
            }
          ]

          if (!isInventoryOpen || !isMobile) {
            conf = {
              name: t('Home'),
              actions
            }
          }
        }
        break
      }
      default:
        conf = undefined
    }

    return conf
  }, [
    pathname,
    isAuthenticated,
    navigate,
    cart,
    setIsFilterOpen,
    isMobile,
    rewardsRedeemables,
    redeemablesToClaimCount
  ])

  return config
}

export default useGetLayoutHeaderConf
