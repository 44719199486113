import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import {
  Box,
  Title,
  Text,
  Flex,
  Tabs,
  Stack,
  Image,
  Button,
  Grid,
  NumberFormatter,
  alpha,
  Loader
} from 'summon-ui/mantine'
import CreditsCard from '@features/credits/components/CreditsCard'
import CreditsLeaderboardTable from '@features/credits/components/CreditsLeaderboardTable'
import FactionsLeaderboardTable from '@features/credits/components/FactionsLeaderboardTable'
import useCredits from '@features/credits/hooks/useCredits'
import InfoCard from '@features/lootdrops/components/InfoCard'

const CreditsView = () => {
  const { creditsStats, creditsStatsLoading } = useCredits()

  const CARD_STATS = useMemo(
    () => [
      {
        id: 1,
        description: 'Total Credits Won',
        amount: creditsStats?.amountUserCredits || 0
      },
      {
        id: 2,
        description: 'Won from Referrals',
        amount: creditsStats?.amountReferralCredits || 0
      },
      {
        id: 3,
        description: 'Won from Quests',
        amount: creditsStats?.amountQuestCredits || 0
      }
    ],
    [creditsStats]
  )

  if (creditsStatsLoading) return <Loader />
  return (
    <Box>
      <Stack gap='xs'>
        <Image src='/images/credits/credit-text.png' maw='580' alt='credits' />
        <Text>Play games, refer friends, win credits</Text>
      </Stack>
      <Flex
        direction={{
          xs: 'column',
          md: 'row'
        }}
        gap='lg'
        py='lg'
      >
        {CARD_STATS.map((card) => (
          <InfoCard
            key={card.id}
            info={card.amount?.toString() || ''}
            bg='transparent'
            description={card.description}
          />
        ))}
      </Flex>
      <Stack gap='lg'>
        <Title order={2} mt='lg'>
          How to win credits
        </Title>
        <Tabs defaultValue='1'>
          <Tabs.List>
            <Tabs.Tab value='1'>Ways to Win Credits</Tabs.Tab>
            <Tabs.Tab value='2'>Leaderboard</Tabs.Tab>
            <Tabs.Tab value='3'>Factions Leaderboard</Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value='1' mt='md'>
            <Grid gutter='lg' grow>
              <Grid.Col span={9}>
                <CreditsCard w='100%' p='lg' from='#46340B' to='#FFA72B' reverse>
                  <CreditsCard.Body
                    noSpacing
                    name='Referrals'
                    image='/images/credits/credit-referrals.png'
                    description='Win a 20% credit bonus by referring your friends to join via your unique link'
                  >
                    <Text
                      p='xs'
                      size='xs'
                      fw='bold'
                      bg={alpha('var(--mantine-color-white)', 0.1)}
                      className='rounded-lg'
                    >
                      <NumberFormatter
                        thousandSeparator
                        value={creditsStats?.amountReferralCredits}
                        className='font-bolder text-yellow-4 pr-1'
                      />
                      Credits earned from referrals
                    </Text>
                    <Link to='/credits/referrals'>
                      <Button
                        c='white'
                        w={{ xs: '100%', md: '200' }}
                        mt='md'
                        variant='default'
                        bg='yellow.6'
                        className='!border-none'
                      >
                        REFER A FRIEND
                      </Button>
                    </Link>
                  </CreditsCard.Body>
                </CreditsCard>
              </Grid.Col>
              <Grid.Col span={{ xs: 9, md: 4 }}>
                <CreditsCard
                  w='100%'
                  p='lg'
                  from='#000000'
                  to='#930A00'
                  elipses={[
                    {
                      top: '-140px',
                      right: '0px',
                      color: '#930A00'
                    }
                  ]}
                >
                  <CreditsCard.Body
                    name='Quests'
                    image='/images/credits/credit-quests.png'
                    description='Complete eligible quests on the Game7 platform to win credits and discover exciting new Web3 games and communities.'
                  >
                    <CreditsCard.Progress
                      value={{
                        current: creditsStats?.amountQuestCredits || 0,
                        max: creditsStats?.maxQuestCredits || 0
                      }}
                    />
                  </CreditsCard.Body>
                  <Link to='/quests'>
                    <Button c='white' mt='lg' variant='default' bg='error.5' className='!border-none' fullWidth>
                      Play
                    </Button>
                  </Link>
                </CreditsCard>
              </Grid.Col>
              <Grid.Col span={{ xs: 9, md: 4 }}>
                <CreditsCard
                  w='100%'
                  p='lg'
                  from='#371267'
                  to='#5E4ADC'
                  elipses={[
                    {
                      top: '-200px',
                      right: '0px',
                      color: '#0842a6'
                    },
                    {
                      top: '-200px',
                      right: '150px',
                      color: '#6c0da1'
                    }
                  ]}
                >
                  <CreditsCard.Body
                    name='HyperPlay'
                    image='/images/credits/hyperplay.png'
                    description='Download HyperPlay and gain additional credits for play-streaks in participating games. The more you play, the more credits you can win!'
                  >
                    <CreditsCard.Progress
                      value={{
                        current: creditsStats?.amountHyperPlayCredits || 0,
                        max: creditsStats?.maxHyperPlayCredits || 0
                      }}
                    />
                  </CreditsCard.Body>
                  <Link to='/quests/hyperplay-games' rel='noopener noreferrer'>
                    <Button c='white' mt='lg' variant='default' bg='violet.5' className='!border-none' fullWidth>
                      Play
                    </Button>
                  </Link>
                </CreditsCard>
              </Grid.Col>
            </Grid>
          </Tabs.Panel>
          <Tabs.Panel value='2' mt='md'>
            <Stack className='h-full overflow-hidden' gap={0}>
              <CreditsLeaderboardTable />
            </Stack>
          </Tabs.Panel>
          <Tabs.Panel value='3' mt='md'>
            <Stack className='h-full overflow-hidden' gap={0}>
              <FactionsLeaderboardTable />
            </Stack>
          </Tabs.Panel>
        </Tabs>
      </Stack>
    </Box>
  )
}

export default CreditsView
