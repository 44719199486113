import { Title } from 'summon-ui/mantine'

const StakeView = () => {
  return (
    <>
      <Title>Stake View</Title>
    </>
  )
}
export default StakeView
