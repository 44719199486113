import { useEffect } from 'react'
import { useReadContract } from 'wagmi'
import { TENANT_CONTRACTS, TENANT_CONFIG } from '@config'
import { ITEMS_BOUND_ABI } from '@constants/Abi/itemsBoundAbi'
import { useViewer } from '@hooks'
import type { ItemContractData } from '@hooks/inventory/useInventoryItems'
import { isStaging } from '@utils/isStaging'

const useRefetchOnChainInventory = () => {
  const { viewer } = useViewer()

  const { refetch: refetchUserOnchainItems }: { data: ItemContractData[] | undefined; refetch: any } = useReadContract({
    address: isStaging ? TENANT_CONTRACTS.testnet.items : TENANT_CONTRACTS.mainnet.items,
    abi: ITEMS_BOUND_ABI,
    chainId: TENANT_CONFIG.network.id,
    functionName: 'getAllItems',
    account: viewer?.walletAddress as `0x${string}`
  })

  useEffect(() => {
    // We refetch itmes on chain here so right after on boarding we have the items of chain of the new user
    refetchUserOnchainItems()
  }, [])
}

export default useRefetchOnChainInventory
