import { useTranslation } from 'summon-ui/intl'
import { Modal, Stack, Text, Group, Button, useForm, Select } from 'summon-ui/mantine'
import { useViewer } from '@hooks'
import useUpdateLineaGuild from './useUpdateLineaGuild'

interface LineaGuildFormValues {
  lineaGuild?: string
}

interface Props {
  isOpen: boolean
  onSuccess: () => void
  onFinish: () => void
}

const LineaGuildFormModal = ({ isOpen, onSuccess, onFinish }: Props) => {
  const t = useTranslation()
  const { viewer } = useViewer()
  const { updateLineaGuild, isUpdatingLineaGuild } = useUpdateLineaGuild()

  const {
    getInputProps,
    onSubmit: onSubmitForm,
    values
  } = useForm<LineaGuildFormValues>({
    initialValues: { lineaGuild: viewer?.customData?.linea?.guild || '' }
  })

  const handleSubmit = async (data: typeof values) => {
    if (data?.lineaGuild) {
      updateLineaGuild({ lineaGuild: data.lineaGuild.toLocaleLowerCase(), onSuccess, onFinish })
    }
  }

  return (
    <Modal title={t('Select Linea Guild')} opened={isOpen} onClose={onFinish} maw={430}>
      <form onSubmit={onSubmitForm(handleSubmit)}>
        <Stack>
          <Text>{t('Save your Linea Guild to complete actions on Linea Guild-based games')}</Text>
          <Select
            {...getInputProps('lineaGuild')}
            data={['Frogwifcat', 'Linus', 'Efrogs', 'Foxy']}
            label={t('Linea Guild')}
            placeholder={t('Linea Guild')}
          />
          <Group justify='space-between' w='100%' mt='sm'>
            <Button onClick={onFinish} variant='outline' miw={115}>
              {t('Cancel')}
            </Button>
            <Button type='submit' loading={isUpdatingLineaGuild} miw={115}>
              {t('Submit')}
            </Button>
          </Group>
        </Stack>
      </form>
    </Modal>
  )
}

export default LineaGuildFormModal
