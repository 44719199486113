import { Box } from 'summon-ui/mantine'
import { CreditsViews } from '@features/credits'

const CreditsPage = () => {
  return (
    <Box className='h-[calc(100vh-100px)]'>
      <CreditsViews />
    </Box>
  )
}

export default CreditsPage
