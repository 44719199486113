import { useMemo } from 'react'
import { useReadContract } from 'wagmi'
import { useQuery } from '@tanstack/react-query'
import { type EnrichedMetadata, getBaseSkinsMetadataList } from '@api/client'
import { TENANT_CONFIG, TENANT_CONTRACTS } from '@config'
import { AVATAR_BOUND_ABI } from '@constants/Abi/avatarBoundAbi'
import type { InventoryItem } from '@generated/generates'
import { SlotType } from '@modules/inventory/hooks/useAvatarStore'
import { isStaging } from '@utils/isStaging'

export interface BaseSkinContractData {
  baseSkinId: string
  tokenUri: string
}

interface Props {
  enabled: boolean
}

const useBaseSkins = ({ enabled }: Props) => {
  const { data: metadataUris }: { data: BaseSkinContractData[] | undefined } = useReadContract({
    address: isStaging ? TENANT_CONTRACTS.testnet.avatar : TENANT_CONTRACTS.mainnet.avatar,
    abi: AVATAR_BOUND_ABI,
    chainId: TENANT_CONFIG.network.id,
    functionName: 'getAllBaseSkins',
    query: {
      enabled
    }
  })

  const filteredContractBaseSkins: BaseSkinContractData[] | [] | undefined = metadataUris
    ?.filter((record: BaseSkinContractData) => record?.tokenUri?.includes('https'))
    .map((record) => ({
      tokenUri: record.tokenUri,
      baseSkinId: record.baseSkinId.toString()
    }))

  const { data: metadataList } = useQuery({
    queryKey: ['metadata', { baseSkinsContractData: filteredContractBaseSkins }],
    queryFn: getBaseSkinsMetadataList,
    enabled: !!filteredContractBaseSkins
  })

  const onChainBaseSkins = useMemo(
    (): InventoryItem[] | undefined =>
      metadataList
        ?.filter((record) => record?.id)
        .map(
          (record: EnrichedMetadata): InventoryItem => ({
            id: record.id,
            imageUrl: record.image,
            glbUrl: record.animation_url,
            slotType: SlotType.Body,
            isEquipped: false,
            count: 1,
            variantColor: '',
            variants: [],
            enableVariantColors: false
          })
        ),
    [metadataList]
  )

  return {
    baseSkins: onChainBaseSkins || []
  }
}

export default useBaseSkins
