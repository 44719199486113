import { Spinner, showNotification } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { Center } from 'summon-ui/mantine'
import { TENANT_CONFIG } from '@config'
import SolanaForm, { type SolanaFormValues } from '@features/settings/views/SolanaForm'
import { useViewer, useUpdateViewerSettings } from '@hooks'
import type { UpdateUserSettingsData } from '@hooks/viewer/useUpdateViewerSettings'
import { SettingItem } from '../components/SettingItem'
import { SettingsHeader } from '../components/SettingsHeader'

const walletSettings = TENANT_CONFIG?.features?.walletSettings

const WalletView = () => {
  const t = useTranslation()
  const { viewer } = useViewer()
  const { updateViewerSettings, isUpdating } = useUpdateViewerSettings()

  const handleSubmit = (data: SolanaFormValues) => {
    const updateData: UpdateUserSettingsData = {
      solanaWalletAddress: data.solanaWalletAddress
    }

    updateViewerSettings(updateData, {
      onSuccess: () => {
        showNotification({
          variant: 'success',
          message: 'Wallet updated successfully'
        })
      },
      onError: () => {
        showNotification({
          variant: 'danger',
          message: 'Unable to save wallet'
        })
      }
    })
  }

  const renderSolanaWallet = () =>
    viewer ? (
      <SolanaForm
        solanaWalletAddress={(viewer?.customData?.solanaWallet?.address as string) || ''}
        onSubmit={handleSubmit}
        isUpdating={isUpdating}
      />
    ) : (
      <Center className='h-32'>
        <Spinner />
      </Center>
    )

  return (
    <>
      <SettingsHeader title={t('Wallets')} subTitle={t('Connect your various wallets to your tenant')} />
      <SettingItem
        name={t('Connected Solana Wallet')}
        description={t('You have connected the following Solana wallet to your account')}
      >
        {walletSettings?.solana && renderSolanaWallet()}
      </SettingItem>
    </>
  )
}

export default WalletView
