import { useGqlQuery } from '@api/gql/gqlServices'
import { GetAllDnaTraitsDocument, type GetAllDnaTraitsQuery } from '@generated/generates'

interface Props {
  enabled: boolean
}

const useDnaTraits = ({ enabled }: Props) => {
  const { data: dnaTraitsData, isLoading: isDnaTraitsLoading } = useGqlQuery<GetAllDnaTraitsQuery>(
    GetAllDnaTraitsDocument,
    {},
    {
      enabled,
      staleTime: Infinity // Data will never be considered stale
    }
  )

  const dnaTraits = dnaTraitsData?.viewer?.inventoryItems

  return {
    dnaTraits,
    isDnaTraitsLoading
  }
}

export default useDnaTraits
