import { useReadContract } from 'wagmi'
import { TENANT_CONFIG, TENANT_CONTRACTS } from '@config'
import { ITEMS_BOUND_ABI } from '@constants/Abi/itemsBoundAbi'
import { isStaging } from '@utils/isStaging'
import type { ItemContractData } from './useInventoryItems'

interface Props {
  enabled: boolean
  userAddress: string | undefined
}

const useInventoryGodMode = ({ enabled, userAddress }: Props) => {
  // Logic to get all items from the collection if an user is Admin role
  const { data: roleData }: { data: ItemContractData[] | undefined } = useReadContract({
    address: isStaging ? TENANT_CONTRACTS.testnet.items : TENANT_CONTRACTS.mainnet.items,
    abi: ITEMS_BOUND_ABI,
    functionName: 'MINTER_ROLE',
    chainId: TENANT_CONFIG.network.id,
    query: {
      enabled: enabled && !!userAddress
    }
  })

  // Get user role
  const { data: hasRole }: { data: ItemContractData[] | undefined } = useReadContract({
    address: isStaging ? TENANT_CONTRACTS.testnet.items : TENANT_CONTRACTS.mainnet.items,
    abi: ITEMS_BOUND_ABI,
    functionName: 'hasRole',
    chainId: TENANT_CONFIG.network.id,
    args: [roleData, userAddress as `0x${string}`],
    query: { enabled: enabled && !!userAddress && !!roleData }
  })

  // Get all contract items to pass if the user has admin role
  const { data: allItemsFromContract }: { data: ItemContractData[] | undefined } = useReadContract({
    address: isStaging ? TENANT_CONTRACTS.testnet.items : TENANT_CONTRACTS.mainnet.items,
    abi: ITEMS_BOUND_ABI,
    functionName: 'getAllItemsAdmin',
    chainId: TENANT_CONFIG.network.id,
    account: userAddress as `0x${string}`,
    args: [userAddress as `0x${string}`],
    query: { enabled: enabled && !!userAddress && !!hasRole }
  })

  return {
    allItemsFromContract,
    hasAdminRole: !!hasRole
  }
}

export default useInventoryGodMode
