import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { TenantImageEnum, useAssetsContext } from 'summon-ui'
import { TENANT_CONFIG } from '@config'
import { useGetCategoryBySlug } from '@hooks'

const isCustomQuestBgsEnabled = TENANT_CONFIG.features.enableQuestPageCustomBgImg

const isQuestsPage = (pathname: string) => {
  return pathname === '/quests'
}

const isQuestPage = (pathname: string) => {
  return /^\/quests\/[a-zA-Z0-9_-]+$/.test(pathname)
}
const isLootdropPage = (pathname: string) => {
  return pathname === '/lootdrop'
}
const isCreditsPage = (pathname: string) => {
  return pathname.match(/.credits.*/)
}
const isLootdropPageDetails = (pathname: string) => {
  return pathname.match(/.lootdrop.*/)
}
const getQuestSlug = (pathname: string) => {
  return pathname.split('/')[2]
}

const useCalculateBg = () => {
  const { getTenantImage } = useAssetsContext()
  const homeBgImg = getTenantImage(TenantImageEnum.HomeBg)
  const [bg, setBg] = useState<string | undefined>(undefined)
  const { pathname } = useLocation()
  const { category, isCategoryLoading } = useGetCategoryBySlug({
    urlSlug: getQuestSlug(pathname),
    enabled: isQuestPage(pathname)
  })

  useEffect(() => {
    let bg
    if (isCustomQuestBgsEnabled) {
      if (isQuestsPage(pathname)) {
        bg = `${TENANT_CONFIG.assetsPrefix}/quests/default.jpg`
      } else if (isQuestPage(pathname) && !isCategoryLoading) {
        bg = category?.backgroundUrl || `${TENANT_CONFIG.assetsPrefix}/quests/default.jpg`
      } else if (isLootdropPage(pathname) || isLootdropPageDetails(pathname)) {
        bg = `/images/lootdrop/lootdrop-bg.jpg`
      } else if (isCreditsPage(pathname)) {
        bg = `/images/credtis/credits-bg.png`
      } else {
        bg = homeBgImg as string
      }
    } else {
      bg = homeBgImg as string
    }
    setBg(bg)
  }, [pathname, isCategoryLoading, isCustomQuestBgsEnabled])

  return bg
}
export default useCalculateBg
