import { Tabs, ScrollArea, Group } from 'summon-ui/mantine'
import type { InventoryItem } from '@generated/generates'
import { SlotType } from '../hooks/useAvatarStore'
import InventorySlot from './InventorySlot'

interface Props {
  isDNA: boolean
  slotTypesToMap: SlotType[]
  isInventoryItemsLoading: boolean
  nextStep: (item: InventoryItem) => void
  clearCategory: (category: SlotType) => void
  groupedItems: { [key: string]: InventoryItem[] }
  selectedOnboardingSkin?: { glbUrl: string } | null
}

const DNATraitsInventory = ({
  slotTypesToMap,
  groupedItems,
  nextStep,
  clearCategory,
  isDNA,
  selectedOnboardingSkin,
  isInventoryItemsLoading
}: Props) => (
  <Tabs defaultValue={SlotType[slotTypesToMap[0]]}>
    <Tabs.List>
      {slotTypesToMap.map((key: SlotType) => (
        <Tabs.Tab value={SlotType[key]} key={SlotType[key]}>
          {SlotType[key].replace(/([a-z])([A-Z])/g, '$1 $2')}
        </Tabs.Tab>
      ))}
    </Tabs.List>
    {slotTypesToMap.map((key: SlotType) => {
      const targetSlotType = SlotType[key] as any
      const itemsPerGroup = groupedItems[SlotType[targetSlotType] as any]

      return (
        <Tabs.Panel data-testid={SlotType[key]} value={SlotType[key]} key={SlotType[key]} mt='md'>
          <ScrollArea.Autosize h='65vh' maw='510px' className='overflow-hidden'>
            <Group wrap='wrap'>
              {itemsPerGroup ? (
                itemsPerGroup.map((inventoryItem: InventoryItem, index: number) => (
                  <InventorySlot
                    key={`${inventoryItem.id}-${index}`}
                    image={inventoryItem.imageUrl}
                    count={inventoryItem.count}
                    onClick={() => (inventoryItem.isEquipped ? clearCategory(key) : nextStep(inventoryItem))}
                    isActive={
                      isDNA ? selectedOnboardingSkin?.glbUrl === inventoryItem.glbUrl : !!inventoryItem.isEquipped
                    }
                    testId={`dna-inventory-slot-${index}`}
                  />
                ))
              ) : (
                <InventorySlot isLoading={isInventoryItemsLoading} />
              )}
            </Group>
          </ScrollArea.Autosize>
        </Tabs.Panel>
      )
    })}
  </Tabs>
)

export default DNATraitsInventory
