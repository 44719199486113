import { Logo } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import type { StyleProp } from 'summon-ui/mantine'
import { Card, Text, Paper, Title, Image, Badge, Button, Flex, Stack, Box } from 'summon-ui/mantine'
import type { StakingCardData } from './types'

type Props = StakingCardData & {
  h?: StyleProp<React.CSSProperties['height']>
}

const StakingCard = ({ title, description, features, bottomImg, className, h }: Props) => {
  const t = useTranslation()

  return (
    <Card px='xl' radius='md' withBorder h={h} className={className}>
      <Stack gap='lg' pt='xl' mb='xl' h='100%'>
        <Paper className='overflow-hidden' w='xl' radius='100%'>
          <Logo size='xl' />
        </Paper>
        <Stack gap='xs'>
          <Flex justify='space-between' align='center' wrap='wrap-reverse'>
            <Title w={300} order={2}>
              {title.text}
            </Title>
            <Badge w={140} color={title.labelBgColor} c={title.labelTextColor} size='lg'>
              {title.label}
            </Badge>
          </Flex>
          <Text size='sm' c='dimmed'>
            {description}
          </Text>
        </Stack>
        <Stack gap='lg'>
          <Button color='blue' fullWidth mt='md' radius='md'>
            {t('Stake')}
          </Button>
          <Stack gap='xs'>
            {features.map((feature, index) => (
              <Flex key={index} gap='xs'>
                <Box>{feature.icon}</Box>
                <Box>{feature.text}</Box>
              </Flex>
            ))}
          </Stack>
        </Stack>
      </Stack>
      <Card.Section>
        <Image src={bottomImg} />
      </Card.Section>
    </Card>
  )
}
export default StakingCard
