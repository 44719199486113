import { useGqlQuery } from '@api/gql/gqlServices'
import { GetCategoryBySlugDocument, type GetCategoryBySlugQuery } from '@generated/generates'
import { useViewer } from '@hooks'

interface Props {
  urlSlug?: string
  enabled?: boolean
}

const useGetCategoryBySlug = ({ urlSlug, enabled = true }: Props) => {
  const { isAuthenticated } = useViewer()

  const { data: categoryData, isLoading: isCategoryLoading } = useGqlQuery<GetCategoryBySlugQuery>(
    GetCategoryBySlugDocument,
    {
      urlSlug,
      userCompletion: isAuthenticated
    },
    {
      enabled: !!urlSlug && isAuthenticated != null && enabled
    }
  )

  return {
    category: categoryData?.category,
    isCategoryLoading
  }
}

export default useGetCategoryBySlug
