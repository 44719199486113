import type { ReactNode } from 'react'
import { Table, type TableColumn, type ResponsiveList } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { Avatar, Flex, Box, Center, NumberFormatter, Card, Title } from 'summon-ui/mantine'
import { EmptyState } from '@components'
import TopUserBubble from '@features/credits/components/TopUserBubble'
import useCreditsLeaderboard, {
  type CreditsLeaderboardTableElement
} from '@features/credits/hooks/useCreditsLeaderboard'

type TableKeys = GeneralTableKeys

type GeneralTableKeys = 'rank' | 'member' | 'credits'

const RANK_COL_W = 40
const MEMBER_COL_W = 300
const CREDITS_COL_W = 150

const CreditsLeaderboardTable = () => {
  const t = useTranslation()
  const { leaderboard, navigateToPage, currentPage, isLoading, isUserOnLeaderboard, topThreeLeaderboardUsers } =
    useCreditsLeaderboard(50)
  const renderRank = (value: number): ReactNode => <Center>{value || '>1000'}</Center>

  const renderNameInfo = (value: CreditsLeaderboardTableElement['member']): ReactNode => {
    return (
      <Flex align='center' justify='left' gap='md' h={48}>
        <Flex align='center' justify='left' gap='sm'>
          <Avatar data-testid='leaderboard-table-avatar-image' src={value.avatarUrl} className='shadow-xl size-10' />
          <Box data-testid='leaderboard-table-wallet-label'>{value.name}</Box>
        </Flex>
      </Flex>
    )
  }

  const columns: TableColumn<CreditsLeaderboardTableElement, TableKeys>[] = [
    {
      name: '#',
      key: 'rank',
      sort: (a, b) => a.rank! - b.rank!,
      render: (value) => renderRank(value as number),
      width: RANK_COL_W
    },
    {
      name: 'Name',
      key: 'member',
      render: (value) => renderNameInfo(value as CreditsLeaderboardTableElement['member']),
      width: MEMBER_COL_W
    },
    {
      name: 'Total Credits Won',
      key: 'credits',
      render: (value) => <NumberFormatter thousandSeparator value={value as number} />,
      sort: (a, b) => a.credits! - b.credits!,
      width: CREDITS_COL_W
    }
  ]

  const responsiveList: ResponsiveList<CreditsLeaderboardTableElement, TableKeys> = {
    member: {
      key: 'member',
      name: 'Member',
      renderElementKey: (value) => (
        <Flex align='center' justify='left' gap='md'>
          <Flex align='center' justify='left' gap='xs'>
            <Avatar src={value.avatarUrl} className='size-10 shadow-xl' />
            <Box>{value.name}</Box>
          </Flex>
        </Flex>
      ),
      renderElementValue: () => null
    },
    rank: {
      key: 'rank',
      name: 'Rank',
      renderElementKey: () => <>{t('Rank')}</>,
      renderElementValue: (value) => <>{value}</>
    },
    credits: {
      key: 'credits',
      name: 'credits',
      renderElementKey: () => <>{t('Total Credits Won')}</>,
      renderElementValue: (value) => <NumberFormatter thousandSeparator value={value || 0} />
    }
  }

  if (leaderboard === null && !isLoading) {
    return (
      <Center h='100%' w='100%'>
        <EmptyState
          title={t('No Leaderboard Found')}
          description={t('We can’t find any activity, come back later')}
          testId='leaderboard'
        />
      </Center>
    )
  }

  return (
    <>
      <Flex
        visibleFrom='md'
        className='rounded-md'
        justify='center'
        gap='15%'
        my='lg'
        py='xl'
        style={{
          backgroundImage: 'url("/images/credits/credit-leaderboard.png")',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat'
        }}
      >
        {topThreeLeaderboardUsers?.map((user) => <TopUserBubble key={user?.username} {...user} />)}
      </Flex>
      <Card p='lg'>
        <Flex align='center' justify='space-between' h='100%'>
          <Title data-testid='history-title-label' order={4}>
            Top Players
          </Title>
        </Flex>
      </Card>
      <Table<CreditsLeaderboardTableElement, TableKeys, TableKeys>
        data={leaderboard}
        dataAlignment={{ rank: 'center', member: 'left', credits: 'center' }}
        columns={columns}
        responsiveList={responsiveList}
        stickyConf={{
          stickyHeader: true,
          stickyHeaderOffset: 0,
          stickyFirstRowOffset: isUserOnLeaderboard ? 43 : undefined
        }}
        paginationConf={{
          currentPage,
          onNavigateToPage: navigateToPage,
          totalItemsCount: 1000,
          totalPagesCount: 20
        }}
        isLoading={isLoading}
      />
    </>
  )
}

export default CreditsLeaderboardTable
