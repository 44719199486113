import { useMediaScreen } from 'summon-ui'
import { Flex, Stack } from 'summon-ui/mantine'
import StakingCard from '@features/staking/components/StakingCard/StakingCard'
import stakingCardsData from '../components/StakingCard/data'

const STAKINGCARD_HEIGHT = 500

const StakingOptionsView = () => {
  const isMobile = useMediaScreen({ type: 'max', breakpoint: 'tablet' })
  const [stakingAndLookupData, lpStakingData] = stakingCardsData

  return (
    <Stack align='center'>
      <Flex gap='xl' direction={isMobile ? 'column' : 'row'}>
        <StakingCard {...stakingAndLookupData} h={STAKINGCARD_HEIGHT} />
        <StakingCard {...lpStakingData} h={STAKINGCARD_HEIGHT} />
      </Flex>
    </Stack>
  )
}
export default StakingOptionsView
