import { useState } from 'react'
import { showNotification } from 'summon-ui'
import { useQueryClient } from '@tanstack/react-query'
import { graphqlClient } from '@api/config'
import { TENANT_CONTRACTS } from '@config'
import { type InventoryInput, useEquipInventoryItemsMutation } from '@generated/generates'
import { useViewer } from '@hooks'
import useAvatarStore, { type SlotType } from '@modules/inventory/hooks/useAvatarStore'
import { isStaging } from '@utils/isStaging'

const DEFAULT_COUNT = 1

interface Props {
  onCallback?: () => void
}

const useEquipItems = ({ onCallback }: Props) => {
  const queryClient = useQueryClient()
  const { viewer } = useViewer()
  const { previewItems } = useAvatarStore()
  const [isEquipSuccess, setIsEquipSuccess] = useState(false)
  const refreshInventoryItemsQuery = () => queryClient.invalidateQueries({ queryKey: ['GetInventoryItems'] })
  const refreshInventoryItemsByIdsQuery = () => queryClient.invalidateQueries({ queryKey: ['GetInventoryItemsByIds'] })

  const itemsToEquip = previewItems
    .filter((item) => item.isEquipped)
    .map((item) => ({
      tokenId: item?.chain?.tokenId?.toString() as string,
      itemId: item?.id,
      soulbound: item?.soulbound as boolean,
      smartContractAddress: item?.chain?.address as string,
      slotType: item.slotType as SlotType,
      count: DEFAULT_COUNT,
      uri: item.uri as string
    }))

  const payload: InventoryInput = {
    playerAddress: viewer?.walletAddress as string,
    avatarContractAddress: isStaging ? TENANT_CONTRACTS?.testnet.avatar : TENANT_CONTRACTS.mainnet.avatar,
    inventory: itemsToEquip
  }

  const { mutateAsync: equipItemsMutation, isPending: isEquippingItems } = useEquipInventoryItemsMutation(
    graphqlClient(),
    {
      onSuccess() {
        refreshInventoryItemsQuery()
        refreshInventoryItemsByIdsQuery()
        setIsEquipSuccess(true)
        onCallback && onCallback()
      },
      onError: () => {
        showNotification({
          variant: 'danger',
          message: 'ERROR equipping inventory Items'
        })
      }
    }
  )

  const equipItems = async () => {
    try {
      await equipItemsMutation({ payload })
    } catch (error) {
      console.error('Error equipping items: ', error)
    }
  }

  return {
    equipItems,
    isEquippingItems,
    isEquipSuccess,
    setIsEquipSuccess,
    equipItemsPayload: payload
  }
}

export default useEquipItems
