import { useEffect, useMemo, useState } from 'react'
import { useReadContract } from 'wagmi'
import { useQueryClient } from '@tanstack/react-query'
import { graphqlClient } from '@api/config'
import { TENANT_CONFIG, TENANT_CONTRACTS } from '@config'
import { ITEMS_BOUND_ABI } from '@constants/Abi/itemsBoundAbi'
import { type InventoryItem, useGetInventoryItemsByIdsQuery } from '@generated/generates'
import useAvatarStore, { type AvatarState, type SlotType } from '@modules/inventory/hooks/useAvatarStore'
import { isStaging } from '@utils/isStaging'
import useViewer from '../viewer/useViewer'
import useBaseSkins from './useBaseSkins'
import useDnaTraits from './useDnaTraits'
import useEquippedDnaItems from './useEquippedDnaItems'
import useInventoryGodMode from './useInventoryGodMode'

export interface ItemContractData {
  tokenId: bigint
  tokenUri: string
  amount: bigint
}

export interface InitialItemData extends ItemContractData {
  address: string
  id: string
  isEquipped: boolean
  slotType: SlotType
  chain: {
    tokenId: string
    address: string
  }
  soulbound: boolean
}

interface Props {
  isDNA?: boolean
  isDNATraits?: boolean
}

const useInventoryItems = ({ isDNA = false, isDNATraits = false }: Props) => {
  const { viewer } = useViewer()
  const queryClient = useQueryClient()
  const refreshInventoryItemsWithMetadata = () =>
    queryClient.invalidateQueries({ queryKey: ['inventoryItemsWithMetadata'] })
  const refreshGetInventoryByIdsQuery = () => queryClient.invalidateQueries({ queryKey: ['GetInventoryItemsByIds'] })
  const refreshGetInventoryItemsQuery = () => queryClient.invalidateQueries({ queryKey: ['GetInventoryItems'] })
  const { baseSkins } = useBaseSkins({ enabled: isDNA })
  const { dnaTraits, isDnaTraitsLoading } = useDnaTraits({ enabled: isDNATraits })
  const { allItemsFromContract, hasAdminRole } = useInventoryGodMode({
    enabled: !isDNA,
    userAddress: viewer?.walletAddress
  })
  const { equippedDnaItems } = useEquippedDnaItems({ enabled: !isDNA && !isDNATraits })

  // Get onchain user Inventory Items. Real items (NFTs) the user has in wallet.
  const {
    data: userOnchainItems,
    refetch: refetchUserOnchainItems
  }: { data: ItemContractData[] | undefined; refetch: any } = useReadContract({
    address: isStaging ? TENANT_CONTRACTS.testnet.items : TENANT_CONTRACTS.mainnet.items,
    abi: ITEMS_BOUND_ABI,
    chainId: TENANT_CONFIG.network.id,
    functionName: 'getAllItems',
    account: viewer?.walletAddress as `0x${string}`,
    query: { enabled: !isDNA && !!viewer?.walletAddress && !hasAdminRole }
  })

  // Onchain items data for a wallet. God mode or real user items
  const userContractItems = hasAdminRole ? allItemsFromContract : userOnchainItems

  const userItemsIdsArray = useMemo(
    () =>
      userContractItems
        ?.filter((record: ItemContractData) => record?.tokenUri?.includes('https'))
        .map((record) => record.tokenId.toString()),
    [userContractItems]
  )

  // Retrieve item data from the database based on the NFTs owned by the user's wallet.
  const { data: userInventoryItemsDbData, refetch: refetchInventoryItemsByIds } = useGetInventoryItemsByIdsQuery(
    graphqlClient(),
    { tokenIds: userItemsIdsArray },
    { enabled: !!userItemsIdsArray?.length }
  )

  const onChainItems = useMemo(
    () => (isDNA ? baseSkins : (userInventoryItemsDbData?.viewer?.inventoryItems as InventoryItem[])),
    [baseSkins, userInventoryItemsDbData, isDNA]
  )

  const items = useMemo(() => {
    if (isDNATraits) {
      return dnaTraits ?? []
    } else {
      return [...(onChainItems ?? []), ...(equippedDnaItems ?? [])]
    }
  }, [onChainItems, dnaTraits, isDNATraits, equippedDnaItems])

  const setItems = useAvatarStore((state: AvatarState) => state.setItems)
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    if (items?.length) {
      setItems(items as InventoryItem[])
      setLoading(false)
    }
  }, [items])

  const isItemsLoading = isDNATraits ? isDnaTraitsLoading : loading

  return {
    inventoryItems: (items as InventoryItem[]) || [],
    isInventoryItemsLoading: isItemsLoading,
    refreshInventoryItemsWithMetadata,
    refreshGetInventoryByIdsQuery,
    refreshGetInventoryItemsQuery,
    refetchInventoryItemsByIds,
    refetchUserOnchainItems
  }
}

export default useInventoryItems
