import { Spinner, TenantImageEnum, useAssetsContext, useMediaScreen } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import { Center, Flex, Stack, Tabs, TabsList, Image } from 'summon-ui/mantine'
import { Breadcrumbs } from '@components'
import type { LootDrop } from '@generated/generates'
import LootdropCategoriesList from '../components/LootdropCategoriesList'
import LootdropInfo from '../components/LootdropInfo'
import LootdropRewardsList from '../components/LootdropRewardsList'
import useLootdrop from '../hooks/useLootdrop'

const LootdropDetailsView = () => {
  const t = useTranslation()
  const { isLoading, lootdrop } = useLootdrop()
  const { getTenantImage } = useAssetsContext()
  const NotFoundImage = getTenantImage(TenantImageEnum.NotFound)
  const isMobile = useMediaScreen({ type: 'max', breakpoint: 'tablet' })
  const isMinTablet = useMediaScreen({ type: 'min', breakpoint: 'tablet' })
  const isMaxDesktop = useMediaScreen({ type: 'max', breakpoint: 'desktop' })
  const isDesktopOrBigger = useMediaScreen({ type: 'min', breakpoint: 'desktop' })

  if (isLoading) {
    return <Spinner mt={150} />
  }

  const renderContentFromTablet = (lootdrop: LootDrop, withBreadcrumbs: boolean) => (
    <>
      <Stack gap='lg'>
        {withBreadcrumbs && <Breadcrumbs lastText={lootdrop.name} />}
        <LootdropInfo lootdrop={lootdrop} />
        {lootdrop.rewards && lootdrop.rewards.length > 0 && (
          <LootdropRewardsList
            participantCount={lootdrop.participantCount}
            userMultiplier={lootdrop.multiplier || 1}
            lootdropTotalMultiplier={lootdrop.totalMultiplier}
            rewards={lootdrop.rewards}
          />
        )}
      </Stack>
      {lootdrop?.categories && lootdrop.categories.length > 0 && (
        <>
          <LootdropCategoriesList categories={lootdrop.categories} lootdropSlug={lootdrop.urlSlug} />
        </>
      )}
    </>
  )

  const renderBig = (lootdrop: LootDrop) => (
    <Flex w='100%' gap='md'>
      {renderContentFromTablet(lootdrop, true)}
    </Flex>
  )

  const renderTablet = (lootdrop: LootDrop) => (
    <Stack w='100%' gap='md'>
      <Breadcrumbs lastText={lootdrop.name} />
      {renderContentFromTablet(lootdrop, false)}
    </Stack>
  )

  const renderMobile = (lootdrop: LootDrop) => (
    <>
      <Breadcrumbs lastText={lootdrop.name} />
      <Tabs defaultValue={t('Overview')} keepMounted={false} mt='md'>
        <Tabs.List pb='lg'>
          <Tabs.Tab value={t('Overview')}>{t('Overview')}</Tabs.Tab>
          <Tabs.Tab value={t('Quests')}>{t('Quests')}</Tabs.Tab>
        </Tabs.List>
        <TabsList>
          <Tabs.Panel value={t('Overview')}>
            <Stack gap='lg'>
              <LootdropInfo lootdrop={lootdrop} />
              {lootdrop.rewards && lootdrop.rewards.length > 0 && (
                <LootdropRewardsList
                  participantCount={lootdrop.participantCount}
                  rewards={lootdrop.rewards}
                  lootdropTotalMultiplier={lootdrop.totalMultiplier}
                  userMultiplier={lootdrop.multiplier || 1}
                />
              )}
            </Stack>
          </Tabs.Panel>
          <Tabs.Panel value={t('Quests')}>
            <Stack gap='lg'>
              {lootdrop?.categories && lootdrop.categories.length > 0 && (
                <LootdropCategoriesList categories={lootdrop.categories} lootdropSlug={lootdrop.urlSlug} />
              )}
            </Stack>
          </Tabs.Panel>
        </TabsList>
      </Tabs>
    </>
  )

  const isTablet = isMinTablet && isMaxDesktop

  return lootdrop ? (
    <Stack>
      {isDesktopOrBigger && renderBig(lootdrop)}
      {isTablet && renderTablet(lootdrop)}
      {isMobile && renderMobile(lootdrop)}
    </Stack>
  ) : (
    <Center h='100%'>
      <Image src={NotFoundImage} />
    </Center>
  )
}

export default LootdropDetailsView
