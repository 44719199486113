import { useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { graphqlClient } from '@api/config'
import { useGqlQuery } from '@api/gql/gqlServices'
import {
  GetUserConsentLootDropDocument,
  type GetUserConsentLootDropQuery,
  useConsentLootDropTermsMutation
} from '@generated/generates'

interface Props {
  lootdropId: string | undefined
  callback?: () => void
}

const useLootdropTerms = ({ lootdropId, callback }: Props) => {
  const queryClient = useQueryClient()
  const [isAcceptingLootdropTerms, setIsAcceptingLootdropTerms] = useState(false)
  const refreshUserConsentLootdropQuery = () => queryClient.invalidateQueries({ queryKey: ['GetUserConsentLootDrop'] })

  const { data } = useGqlQuery<GetUserConsentLootDropQuery>(
    GetUserConsentLootDropDocument,
    {
      lootDropId: lootdropId
    },
    { enabled: !!lootdropId }
  )

  const { mutateAsync: consentLootdropTermsMutation } = useConsentLootDropTermsMutation(graphqlClient(), {
    onSuccess() {
      refreshUserConsentLootdropQuery()
      callback && callback()
    }
  })

  const acceptLootdropTerms = async () => {
    if (lootdropId) {
      setIsAcceptingLootdropTerms(true)
      try {
        await consentLootdropTermsMutation({ lootDropId: lootdropId })
      } catch (error) {
        console.error('Error accepting Lootdrop terms: ', error)
      } finally {
        setIsAcceptingLootdropTerms(false)
        refreshUserConsentLootdropQuery()
      }
    }
  }

  return {
    userAcceptedLootdropTerms: data?.userConsentLootDrop,
    acceptLootdropTerms,
    isAcceptingLootdropTerms
  }
}

export default useLootdropTerms
