import { TENANT_CONFIG, TENANT_MARKETPLACE } from '@config'
import { isStaging } from '@utils/isStaging'

const DEFAULT_AVATAR_IMAGE = '/images/default-avatar-icon.png'
const NAVIGATION_MENU = [
  {
    name: 'Home',
    iconName: 'HomeLine',
    to: '/'
  },
  {
    name: 'Quests',
    iconName: 'LayersThree01',
    to: '/quests'
  },
  ...(TENANT_CONFIG.features.lootdrops
    ? [
        {
          name: 'Lootdrop',
          iconName: 'Coins03',
          to: '/lootdrop',
          isNew: true
        }
      ]
    : []),
  {
    name: 'Credits',
    iconName: 'CoinsHand',
    to: '/credits',
    isNew: true
  },
  ...(isStaging
    ? [
        {
          name: 'Staking',
          iconName: 'CoinsHand',
          to: '/staking',
          isNew: true
        }
      ]
    : []),
  // {
  //   name: 'Proposals',
  //   iconName: 'BarChartSquare02',
  //   to: '/proposals',
  //   soon: !TENANT_CONFIG.features.proposals
  // },
  {
    name: 'Leaderboard',
    iconName: 'Users01',
    to: '/leaderboard',
    soon: !TENANT_CONFIG.features.leaderboard
  },
  {
    name: 'Marketplace',
    iconName: 'ShoppingBag02',
    to: '/marketplace',
    soon: !TENANT_MARKETPLACE.enabled
  },
  {
    name: 'History',
    iconName: 'CalendarCheck01',
    to: '/history',
    locked: true
  }
]
const BOTTOM_MENU = [
  {
    name: 'Settings',
    to: '/settings',
    iconName: 'Settings01'
  }
]

export { DEFAULT_AVATAR_IMAGE, NAVIGATION_MENU, BOTTOM_MENU }
