import { useQueryClient } from '@tanstack/react-query'
import { graphqlClient } from '@api/config'
import { useCompleteUserOnboardingMutation, useSaveAvatarMutation } from '@generated/generates'

const useOnboardingUtils = () => {
  const queryClient = useQueryClient()
  const refreshUserQuery = () => queryClient.invalidateQueries({ queryKey: ['GetUser'] })
  const refreshInventoryItemsQuery = () => queryClient.invalidateQueries({ queryKey: ['GetInventoryItems'] })

  const { mutateAsync: saveAvatar, isPending: isSavingAvatar } = useSaveAvatarMutation(graphqlClient(), {
    onSuccess() {
      refreshUserQuery()
      refreshInventoryItemsQuery()
    }
  })

  const { mutateAsync: completeOnboarding, isPending: isCompletingOnboarding } = useCompleteUserOnboardingMutation(
    graphqlClient(),
    {
      onSuccess: () => refreshUserQuery()
    }
  )

  return {
    refreshUserQuery,
    refreshInventoryItemsQuery,
    saveAvatar,
    isSavingAvatar,
    completeOnboarding,
    isCompletingOnboarding
  }
}

export default useOnboardingUtils
