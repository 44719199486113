import { Route, Routes } from 'react-router-dom'
import { ErrorBoundary } from '@components'
import StakeView from './views/Stake.view'
import StakingView from './views/Staking.view'
import StakingOptionsView from './views/StakingOptions.view'

export const StakingViews = () => {
  return (
    <>
      <Routes>
        <Route path='/' index element={<StakingView />} errorElement={<ErrorBoundary />} />
        <Route path='/options' index element={<StakingOptionsView />} errorElement={<ErrorBoundary />} />
        <Route path='/options/stake' index element={<StakeView />} errorElement={<ErrorBoundary />} />
      </Routes>
    </>
  )
}
