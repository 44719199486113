import { Spinner, TenantImageEnum, useAssetsContext, useMediaScreen } from 'summon-ui'
import { LootdropProgress } from 'summon-ui/g7'
import { useTranslation } from 'summon-ui/intl'
import { Center, Flex, Image, Stack, Title, Text, Badge, Button, TypographyStylesProvider } from 'summon-ui/mantine'
import { ReadMore, Breadcrumbs } from '@components'
import QuestsContainerList from '@features/quests/components/QuestsContainerList'
import { useGetCategoryBySlug, useViewer } from '@hooks'
import { calculateTotalRewardUnits } from '@utils/questsHelpers'
import { useWeb3Modal } from '@web3modal/wagmi/react'
import InfoCard from '../components/InfoCard'
import useLootdrop from '../hooks/useLootdrop'

const LootdropCategoryDetailsView = ({ urlSlug }: { urlSlug: string }) => {
  const t = useTranslation()
  const { isAuthenticated } = useViewer()
  const { open: openConnectModal } = useWeb3Modal()
  const isMaxTablet = useMediaScreen({ type: 'max', breakpoint: 'tablet' })
  const { getTenantImage } = useAssetsContext()
  const NotFoundImage = getTenantImage(TenantImageEnum.NotFound)
  const questPlaceholder = getTenantImage(TenantImageEnum.QuestPlaceholder)
  const { category, isCategoryLoading } = useGetCategoryBySlug({ urlSlug })
  const { isLoading: isLootdropLoading, lootdrop } = useLootdrop()

  if (isLootdropLoading || isCategoryLoading) {
    return (
      <Center h='100%' w='100%'>
        <Spinner />
      </Center>
    )
  }

  const categoryMultiplier = lootdrop?.multiplierPerCategory
    ? `+${lootdrop?.multiplierPerCategory}x ${t('Multiplier')}`
    : undefined
  const totalRewardUnits = calculateTotalRewardUnits(category?.rewards)
  const rewardString = totalRewardUnits === 1 ? t('Wearable') : t('Wearables')
  const totalRewardUnitsDisplay = totalRewardUnits ? `${totalRewardUnits} ${rewardString}` : undefined
  const isUpcoming = new Date(category?.startTime) > new Date()
  const isCategoryCompleted = category?.completionPercentage === 100

  return category ? (
    <Stack gap='xl' pb='xl'>
      <Breadcrumbs lastText={category.name} />
      <Flex direction={isMaxTablet ? 'column' : 'row'} gap='lg' align='center'>
        <Image
          data-testid='lootdrop-category-hero-image'
          alt='quest'
          src={category?.imageUrl || questPlaceholder}
          radius='md'
          w={isMaxTablet ? '100%' : 500}
        />
        <Stack gap='sm' w={isMaxTablet ? '100%' : '50%'}>
          <Title data-testid='category-name-label' fw='bold' lineClamp={2}>
            {category?.name}
          </Title>
          {isCategoryCompleted && (
            <Badge bg='green.9' size='lg'>
              {t('Completed')}
            </Badge>
          )}
          <ReadMore maxHeight={120}>
            <TypographyStylesProvider>
              <Text
                data-testid='category-description-label'
                dangerouslySetInnerHTML={{ __html: category?.description || '' }}
              />
            </TypographyStylesProvider>
          </ReadMore>
          {isAuthenticated ? (
            <Stack>
              <Text>{t('Progress')}</Text>
              <LootdropProgress value={category?.completionPercentage || 0} />
              <Flex gap='xs' mt='xs'>
                <InfoCard
                  info={`${lootdrop?.multiplier?.toString() ?? '1'}x`}
                  description={t('Current Multiplier')}
                  tooltipInfo={{
                    label: t(
                      'Your multiplier increases your chance at receiving rare loot. Level up in the portal and complete  LootDrop quests to increase your multiplier.'
                    ),
                    w: 200
                  }}
                />

                {categoryMultiplier && <InfoCard info={categoryMultiplier} description={t('Reward')} />}
                {totalRewardUnitsDisplay && <InfoCard info={totalRewardUnitsDisplay} description={t('Reward')} />}
              </Flex>
            </Stack>
          ) : (
            <Button onClick={() => openConnectModal()} maw={160}>
              {t('LOGIN / SIGN UP')}
            </Button>
          )}
        </Stack>
      </Flex>
      <QuestsContainerList categoryId={category.id} lootdropId={lootdrop?.id} isLocked={isUpcoming} />
    </Stack>
  ) : (
    <Center h='100%'>
      <Image src={NotFoundImage} />
    </Center>
  )
}

export default LootdropCategoryDetailsView
