import { useState } from 'react'
import { EnhancedPaper } from 'summon-ui'
import { useTranslation } from 'summon-ui/intl'
import {
  alpha,
  BackgroundImage,
  Button,
  Center,
  Flex,
  Image,
  Modal,
  Overlay,
  rgba,
  Stack,
  Text,
  Title,
  useMantineTheme
} from 'summon-ui/mantine'
import { AssetsPrefix } from '@config/enum'
import type { RewardRedeemed } from '@generated/generates'
import { useRedeem } from '@hooks'

const LootdropReward = ({ reward }: { reward: RewardRedeemed }) => {
  const { colors } = useMantineTheme()
  const t = useTranslation()
  const rewardData = reward && {
    ...reward,
    ...rewardsData.find((data) => reward.title.toLocaleLowerCase().includes(data.key))
  }
  const { redeem, isRedeeming } = useRedeem({
    isSponsored: true,
    redeemableId: reward.id
  })
  const [rewardModal, setShowRewardModal] = useState(false)
  const [lootDropOpen, setLootDropOpen] = useState(false)
  const openReward = () => {
    setLootDropOpen(true)
  }

  const claim = () => {
    redeem().then(() => setShowRewardModal(false))
  }
  return (
    <>
      <EnhancedPaper withBorder withBg radius='md' className='!border-lime-9'>
        <Flex align='center' gap='md' bg={rgba(colors.lime[8], 0.15)} px='lg' py='md'>
          <Image maw={100} src={`${AssetsPrefix.G7}/lootboxes/loot.png`} alt='lootdrop box' />
          <Flex wrap='wrap' justify='space-between' align='center' w='100%' gap='md'>
            <Title order={3}> {t('Loot acquired')}</Title>
            <Button variant='white' tt='uppercase' onClick={openReward}>
              {t('Open box')}
            </Button>
          </Flex>
        </Flex>
      </EnhancedPaper>
      <Modal withCloseButton={false} opened={rewardModal} onClose={() => setShowRewardModal(false)}>
        <Stack align='center' gap='md'>
          <Title order={2}>{reward.title}</Title>
          <BackgroundImage src={`${AssetsPrefix.G7}/lootboxes/loot-reward-bg.jpeg`} radius='md'>
            <Center bg={alpha('black', 0.5)} h={260} className='flex flex-col'>
              <Image src={`${AssetsPrefix.G7}/lootboxes/mantle.png`} h={100} w={100} />
              <Title mt='md'>{reward.description}</Title>
              <Text ta='center' maw='300'>
                {rewardData.key === RewardsVariant.LEGENDARY ? (
                  <>
                    {t('You won the Legendary LootBox!')} 🎉
                    <Text c='gray' fz='sm'>
                      {t('A member of our team will reach out via Discord and/or Twitter to distribute')} 🙌
                    </Text>
                  </>
                ) : (
                  t('LootDrop Reward')
                )}
              </Text>
            </Center>
          </BackgroundImage>
          {rewardData.key === RewardsVariant.LEGENDARY ? (
            <Button variant='outline' mt='lg' color='white' fullWidth onClick={() => setShowRewardModal(false)}>
              {t('Close')}
            </Button>
          ) : (
            <Button loading={isRedeeming} variant='outline' mt='lg' color='white' fullWidth onClick={claim}>
              {t('Claim')}
            </Button>
          )}
        </Stack>
      </Modal>
      {lootDropOpen && (
        <Overlay
          onClick={() => {
            setLootDropOpen(false)
            setShowRewardModal(true)
          }}
        >
          <Center className='h-screen flex align-center rounded-full'>
            <video
              width='500'
              height='500'
              autoPlay
              muted
              onEnded={() => {
                setLootDropOpen(false)
                setShowRewardModal(true)
              }}
            >
              <source src={`${AssetsPrefix.G7}${rewardData?.link}`} type='video/webm' />
            </video>
          </Center>
        </Overlay>
      )}
    </>
  )
}

export default LootdropReward

// hacky way to get the video to play
enum RewardsVariant {
  LEGENDARY = 'legendary',
  UNCOMMON = 'uncommon',
  RARE = 'rare',
  COMMON = 'common',
  WEEKLY = 'weekly'
}

const rewardsData = [
  {
    key: RewardsVariant.LEGENDARY,
    link: '/lootboxes/legendary.webm'
  },
  {
    key: RewardsVariant.UNCOMMON,
    link: '/lootboxes/uncommon.webm'
  },
  {
    key: RewardsVariant.RARE,
    link: '/lootboxes/rare.webm'
  },
  {
    key: RewardsVariant.COMMON,
    link: '/lootboxes/common.webm'
  },
  {
    key: RewardsVariant.WEEKLY,
    link: '/lootboxes/weekly.webm'
  }
]
